export default function propsProvider(props) {
    const { navigateTo, intl, cart, dispatch, deleteItemsHandler } = props;

    const { storageCartItems, isCheckedAll } = cart;

    const cartItems = Object.entries(storageCartItems).map(([key, value]) => {
        return {
            stallId: key,
            stallName: value.name,
            isChecked: value.isChecked,
            items: Object.entries(value.items).map(([key, value]) => {
                return {
                    productId: key,
                    ...value
                };
            })
        };
    });

    return {
        navigateTo,
        intl,
        cartItems,
        isCheckedAll,
        dispatch,
        deleteItemsHandler,
        emptyCartProps: {
            navigateTo,
            intl
        },
        detailCartProps: {
            cartItems,
            navigateTo,
            intl,
            dispatch
        }
    };
}
