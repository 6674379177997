import React from 'react';
import BriefGroupType from './BriefGroupType';
import BriefButtonGroup from './BriefButtonGroup';
import {getDiscount} from 'utils/getPercentage';
import {FaSearchPlus} from 'react-icons/fa';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';
import {FormattedNumber} from 'react-intl';
import DOMPurify from 'dompurify';

const BriefDetail = (props) => {
    const {
        quantityIncrement,
        quantityDecrement,
        quantityDemand,
        quantitySupply,
        groupType,
        handleGroupType,
        groupPrice,
        isLogin,
        productImgs,
        handleProductViewedImage,
        handlePreviewImage,
        isPreviewingImg,
        setIsPreviewingImg,
        previewImgCoord,
        imgPreviewScale,
        imgPreviewLength,
        imgListRef,
        activeImageUrl,
        isImgListOverflowing,
        handleScrollLeft,
        handleScrollRight,
        intl,
        productInfo
    } = props;
    const {x, y} = previewImgCoord;
    const {price, origin_price} = groupPrice;

    return (
        <div className='container-fluid m-0 p-0'>
            {productInfo && (
                <div className='row m-0 brief-details'>
                    <div className='col-lg-5 col-md-6 col-sm-12 col-xs-12 text-center px-0 my-0 px-md-2 my-md-3 position-relative'>
                        <div className='brief-details__img'>
                            <div
                                className='zoom-container d-flex flex-column align-items-center justify-content-center w-100'
                                onMouseMove={handlePreviewImage}
                                onMouseLeave={() => setIsPreviewingImg(false)}>
                                <div className='zoom-container__img'>
                                    <img
                                        src={activeImageUrl}
                                        alt='preview'
                                        width={imgPreviewLength}
                                        height={imgPreviewLength}
                                    />
                                    <div
                                        className='zoom-container__img--lens d-md-block d-none'
                                        style={{left: x, top: y}}></div>
                                </div>
                                <div className='zoom-container__description d-none d-md-flex align-items-center'>
                                    <span className='me-1'>
                                        <FaSearchPlus />
                                    </span>
                                    <span>{intl.messages.zoomImage}</span>
                                </div>
                            </div>

                            {productImgs.length > 0 && (
                                <div className='d-flex align-items-center justify-content-center position-relative img-list'>
                                    {isImgListOverflowing && (
                                        <div className='img-list__control control-left'>
                                            <MdChevronLeft
                                                fontSize={30}
                                                onClick={() => handleScrollRight(imgListRef.current, 50)}
                                            />
                                        </div>
                                    )}
                                    <div className='img-list__content d-flex' ref={imgListRef}>
                                        {productImgs.map((img, idx) => (
                                            <div
                                                key={idx}
                                                className={`img-item ${img.isActive ? 'item-active' : ''}`}
                                                onMouseEnter={() => handleProductViewedImage(img.url)}>
                                                <img src={img.url} alt='product-img' />
                                            </div>
                                        ))}
                                    </div>
                                    {isImgListOverflowing && (
                                        <div className='img-list__control control-right'>
                                            <MdChevronRight
                                                fontSize={30}
                                                onClick={() => handleScrollLeft(imgListRef.current, 50)}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                       
                    </div>
                    <div className='col-lg-7 col-md-6 col-sm-12 col-xs-12 border-sm-none border-left px-0 my-0 px-md-2 my-md-3 '>
                        <div className='brief-details__content'>
                            <div className='content-block'>
                                <h3>{productInfo.name}</h3>
                                <div className='pb-2 d-flex border-bottom border-sm-none'>
                                    <div className='content-block__brand me-3'>
                                        <span>{intl.messages.brand}: </span>
                                        <span className='content-block__brand--name'>{productInfo.brand_name}</span>
                                    </div>

                                    <div className='content-block__sku'>
                                        <span>{intl.messages.SKUproduct}: </span>
                                        <span>{productInfo.sku}</span>
                                    </div>
                                </div>

                                <div className='content-block__price'>
                                    <span className='content-block__price--final me-3'>
                                        <span className='text-decoration-underline'>đ</span>
                                        <span>
                                            <FormattedNumber value={price} />
                                        </span>
                                    </span>
                                    {origin_price > 0 && (
                                        <>
                                            <span className='content-block__price--initial text-decoration-line-through'>
                                                <span className='text-decoration-underline'>đ</span>{' '}
                                                <FormattedNumber value={origin_price} />
                                            </span>
                                            <div>
                                                <span>{intl.messages.saving}: </span>
                                                <span className='text-decoration-underline'>đ</span>
                                                <span>
                                                    {' '}
                                                    <FormattedNumber value={origin_price - price} />{' '}
                                                </span>
                                                <span>({getDiscount(price, origin_price)}%)</span>
                                            </div>
                                        </>
                                    )}
                                </div>

                                {productInfo.extend_type && (
                                    <div className='content-block__group mt-4'>
                                        {productInfo.extend_type.map((type, idx) => (
                                            <BriefGroupType
                                                activeGroupType={groupType[idx]}
                                                handleGroupType={handleGroupType}
                                                group_type={type}
                                                key={idx}
                                            />
                                        ))}
                                    </div>
                                )}

                                <div className='content-block__quantity d-flex align-items-center'>
                                    <span>{intl.messages.quantity}: </span>
                                    <div className='row align-items-center flex-nowrap content-block__quantity--control ms-0 me-2'>
                                        <div
                                            className='control-minus col text-center h-100'
                                            onClick={quantityDecrement}></div>
                                        <div className='quantity-demand col text-center h-100'>{quantityDemand}</div>
                                        <div className='control-plus col text-center' onClick={quantityIncrement}>
                                            +
                                        </div>
                                    </div>

                                    <div className='content-block__quantity--product'>
                                        <span>{intl.messages.productLeft}</span>
                                        <span> {quantitySupply} </span>
                                        <span>{intl.messages.product}</span>
                                    </div>
                                </div>

                                <div className='d-md-block d-none'>
                                    <BriefButtonGroup intl={intl} />
                                </div>
                            </div>

                            <div className='content-block'>
                                <div className='content-block__summary'>
                                    <h5 className='d-block d-md-none'>{intl.messages.overviewDescription}</h5>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(productInfo.summary)
                                        }}></div>
                                </div>
                            </div>

                            <div className='content-block d-md-none d-block position-static position-xs-sticky bottom-0'>
                                <BriefButtonGroup intl={intl} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BriefDetail;
