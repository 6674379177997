import {mail, navigation_map, phone} from 'assets/images';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import React from 'react';

export default function FooterMainView(props) {
    const {intl, contactInfo, customerServiceData, paymentMethodData, appInfo} = props;

    const contactInfoData = [
        {label: contactInfo.BusinessRegistrationNumber, span: 'Số ĐKKD: '},
        {label: contactInfo.TaxCode, span: 'MST: '},
        {label: contactInfo.CompanyAddress, span: 'Địa chỉ: '},
        {label: contactInfo.Hotline, span: 'Điện thoại: '},
        {label: contactInfo.Email, span: 'Email: '},
        {label: contactInfo.WebSiteLink, span: 'Website: '},

    ];

    return (
        <footer className='footer'>
            <WrapperContainer>
                <div className='row'>
                    <div className='col-12 col-sm-6 col-md-7 col-lg-5'>
                        <div className='footer-block'>
                            <div className='footer-block__title mb-2'>{intl.messages.contactLabel}</div>

                            {contactInfoData.map((contact) => (
                                <div className='footer-block__info' key={contact.label}>
                                    <span className='contact-span'>{contact.span}</span>
                                    <span className='ms-1'>{contact.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='col-12 col-sm-6 col-md-5 col-lg-4'>
                        <div className='footer-block service-block'>
                            <div className='footer-block__title mb-2'>{intl.messages.serviceLabel}</div>

                            {customerServiceData.map((service) => (
                                <div className='footer-block__info' key={service.label}>
                                    <span>{service.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='col-7 col-sm-6 col-md-7 col-lg-3'>
                        <div className='footer-block payment-block'>
                            <div className='footer-block__title mb-2'>{intl.messages.paymentLabel}</div>

                            <div className='footer-block__info'>
                                <div className='footer-payment d-flex align-items-center gap-2'>
                                    {paymentMethodData.map((method) => (
                                        <img
                                            key={method.label}
                                            className='payment-img'
                                            src={method.image}
                                            alt={method.label}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col'>
                        <div className='footer-block app-block'>
                            <div className='footer-block__title mb-2'>{intl.messages.appDownloadLabel}</div>

                            <div className='d-flex flex-column'>
                                {appInfo.map((app, idx) => (
                                    <a href={app.Link} key={app.Label}>
                                        <img
                                            src={app.Image}
                                            alt={app.Label}
                                            width={120}
                                            className={idx !== 0 ? 'mt-2' : ''}
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperContainer>
        </footer>
    );
}
