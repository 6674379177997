import React from 'react';
import {errorMark} from 'assets/images';
import AuthFooter from 'components/AuthFooter/AuthFooter';
import {Controller, useForm} from 'react-hook-form';
import {FormControl, FormHelperText} from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import {pagePath} from 'configuration/routeConfig';
import CustomButton from 'components/CustomButton/CustomButton';
import {ornament1, oval, ornamentMobile, ovalMobile, ornament2x} from 'assets/images';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import AuthModal from 'components/AuthModal/AuthModal';

const Signup = ({handleInvalidSubmit, handleSignupValid, navigateTo, footerProps}) => {
    const {
        control,
        handleSubmit,
        watch,
        getValues,
        formState: {errors}
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
            repassword: ''
        }
    });
    const watchAllFields = watch();
    const checkButtonDisabled = Object.values(watchAllFields).some((field) => field.length === 0);

    return (
        <AuthLayout src1={ornament2x} src2={ornament1} src3={oval} src4={ornamentMobile} src5={ovalMobile}>
            <AuthModal>
                <div className='signup'>
                    <div className='signup-header'>
                        <h2>Tạo tài khoản mới</h2>
                    </div>
                    <form
                        method='post'
                        action='#'
                        className='w-100'
                        onSubmit={handleSubmit(handleSignupValid, handleInvalidSubmit)}
                        noValidate>
                        <FormControl className='w-100 mb-4'>
                            <Controller
                                name='email'
                                control={control}
                                render={({field}) => (
                                    <CustomInput
                                        {...field}
                                        label={`Email đăng ký`}
                                        placeholder={`Nhập email của bạn tại đây`}
                                        type='text'
                                        novalidate
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl className='w-100 mb-4'>
                            <Controller
                                name='password'
                                control={control}
                                render={({field}) => (
                                    <CustomInput
                                        {...field}
                                        label='Mật khẩu'
                                        placeholder='Vui lòng nhập mật khẩu của bạn'
                                        type='password'
                                        ishavingpwicon='true'
                                        novalidate
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl className='w-100 mb-4'>
                            <Controller
                                name='repassword'
                                control={control}
                                rules={{
                                    validate: (value) =>
                                        value === getValues('password') || 'Mật khẩu không khớp vui lòng thử lại'
                                }}
                                render={({field}) => (
                                    <CustomInput
                                        {...field}
                                        label='Xác nhận lại Mật khẩu'
                                        placeholder='Vui lòng nhập lại mật khẩu mới'
                                        type='password'
                                        valid={!errors['repassword']}
                                        endicon={!errors['repassword'] ? null : errorMark}
                                        ishavingpwicon='true'
                                    />
                                )}
                            />
                            {errors['repassword'] && (
                                <FormHelperText error>{errors['repassword'].message}</FormHelperText>
                            )}
                        </FormControl>

                        <div className='w-100 mb-2'>
                            <CustomButton type='submit' disabled={checkButtonDisabled}>
                                Đăng ký
                            </CustomButton>
                        </div>

                        <div className='mb-2 signup-support'>
                            Đã có tài khoản?{' '}
                            <span className='signup-support__register' onClick={() => navigateTo(pagePath.loginUrl)}>
                                Đăng nhập
                            </span>
                        </div>
                        <AuthFooter {...footerProps} />
                    </form>
                </div>
            </AuthModal>
        </AuthLayout>
    );
};
export default Signup;
