import {Box, InputAdornment, TextField} from '@mui/material';
import {eyes, eyeSee} from 'assets/images';
import React, {forwardRef, useState} from 'react';

const CustomInput = forwardRef((props, ref) => {
    const {
        starticon = null,
        endicon = null,
        valid = true,
        ishavingpwicon = false,
        inputShrink = true,
        novalidate = false,
        ...validTextAttributes
    } = props;
    const {type, className, disabled = false} = validTextAttributes;
    const [isShowPassword, setIsShowPassword] = useState(false);

    return (
        <TextField
            {...Object.assign({}, validTextAttributes, {valid: undefined})}
            className={`customInput ${className} customInput-${novalidate ? '' : valid ? 'valid' : 'invalid'}`}
            ref={ref}
            variant='outlined'
            type={isShowPassword ? 'text' : type}
            fullWidth
            // error={!valid}
            minRows={4}
            InputLabelProps={{
                shrink: inputShrink
            }}
            InputProps={{
                startAdornment: starticon && (
                    <InputAdornment position='start'>
                        <img src={starticon} alt='' />
                    </InputAdornment>
                ),
                endAdornment: (endicon || ishavingpwicon) && (
                    <InputAdornment position='end' sx={{gap: '8px'}}>
                        {ishavingpwicon && (
                            <Box sx={{cursor: 'pointer'}} onClick={() => setIsShowPassword(!isShowPassword)}>
                                {isShowPassword ? <img src={eyeSee} alt='show-pw' /> : <img src={eyes} alt='hide-pw' />}
                            </Box>
                        )}
                        {!valid && <img src={endicon} alt='' />}
                    </InputAdornment>
                )
            }}
            disabled={disabled}
        />
    );
});

export default CustomInput;
