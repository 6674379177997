import {pagePath} from 'configuration/routeConfig';
import {authenticate} from 'features/Auth/authSlice';
import React from 'react';
import {useIntl} from 'react-intl';
import {checkValidationField} from 'utils/checkValidationField';
import {Toast} from 'utils/toast';
import propsProvider from './setPasswordPropsProvider';
import {registerUser, resetPasswordReq} from './setPasswordSlice';
import SetPasswordMainView from './template/SetPasswordMainView';

const SetPasswordContainer = (props) => {
    const {dispatch, history, setPassword} = props;
    const {email, storedSecretKey, phone} = setPassword;
    const intl = useIntl();

    const navigateTo = (path) => {
        history.push(path);
    };

    const handleInvalidSubmit = (_, event) => {
        event.target?.classList.add('wasvalidated');
    };

    const handleRedirectLogin = async (data) => {
        try {
            if (!checkValidationField(data)) {
                Toast(false, intl.messages.validationError);
                return;
            }

            const handleLogin = await dispatch(authenticate(data)).unwrap();
            const {success, message} = handleLogin;
            if (success) {
                const timeout = () => {
                    navigateTo(pagePath.rootUrl);
                };
                Toast(success, message);
                setTimeout(timeout, 2000);
            } else Toast(success, message);
        } catch (err) {
            Toast(false, intl.messages.loginErrorMessage);
        }
    };

    const handleSetpassword = async (data) => {
        if (email !== '' || phone !== '') {
            try {
                if (!checkValidationField(data)) {
                    Toast(false, intl.messages.validationError);
                    return;
                }

                let username = email !== '' ? email : phone;
                const passwordProps = {
                    password: data.password
                };
                const registerProps = Object.assign({}, passwordProps, email !== '' ? {email} : {phone});
                const signupResponse = await dispatch(registerUser(registerProps)).unwrap();
                const {success, message} = signupResponse;

                // handle toast
                if (success) {
                    Toast(success, message);
                    handleRedirectLogin({username, password: data.password});
                } else {
                    Toast(success, message);
                }
            } catch (err) {
                Toast(false, intl.messages.signupErrorMessage);
            }
        } else {
            try {
                if (!checkValidationField(data)) {
                    Toast(false, intl.messages.validationError);
                    return;
                }

                const resetPwProps = {
                    newPassword: data.password,
                    secretkey: storedSecretKey
                };
                const res = await dispatch(resetPasswordReq(resetPwProps)).unwrap();
                const {success} = res;
                if (success) {
                    Toast(success, 'Đổi mật khẩu thành công.');
                } else {
                    Toast(success, res?.message);
                }
                setTimeout(() => {
                    navigateTo(pagePath.loginUrl);
                }, 2000);
            } catch (err) {
                Toast(false, intl.messages.errorMessage);
            }
        }
    };

    const setPasswordContainerProps = {
        navigateTo,
        handleInvalidSubmit,
        handleSetpassword,
        dispatch
    };

    return <SetPasswordMainView {...propsProvider(setPasswordContainerProps)} />;
};

export default SetPasswordContainer;
