import {FormControl, FormGroup} from '@mui/material';
import {close} from 'assets/images';
import React from 'react';
import RatingStar from './RatingStar';
import SearchFilterExpand from './SearchFilterExpand';
import SearchFilterPrice from './SearchFilterPrice';

const SearchFilter = (props) => {
    const {
        intl,
        showSearchFilterState,
        activeRecommendedRange,
        selectRecommendedPrice,
        handlePriceChange,
        handleCheckedChange,
        resetFilter,
        applyFilter,
        recommededPriceRange,
        searchFilterRef,
        closingSearchFilter,
        subSubCategoryChecked,
        priceRange,
        setPriceRange,
        ratingData,
        ratingChecked,
        subCategoryData = [],
        subSubCategoryData = [],
        subCategoryChecked = []
    } = props;

    return (
        <div className='product-page__searchFilter' data-state={showSearchFilterState} ref={searchFilterRef}>
            <div className='search-filter__header d-flex d-sm-none align-items-center justify-content-between mb-3'>
                <h4>{intl.messages.filterTool}</h4>
                <img className='d-flex d-sm-none' src={close} alt='close search filter' onClick={closingSearchFilter} />
            </div>

            {subCategoryData.length > 0 && (
                <div className='search-filter__header'>
                    <h4>{intl.messages.allSubCategories}</h4>

                    <form method='POST' action='#'>
                        <FormControl fullWidth>
                            <FormGroup className='filter-options filter-category'>
                                <SearchFilterExpand
                                    intl={intl}
                                    label='subCategory'
                                    data={subCategoryData}
                                    checkedState={subCategoryChecked}
                                    handleCheckedChange={handleCheckedChange}
                                />
                            </FormGroup>
                        </FormControl>
                    </form>
                </div>
            )}

            <div className='search-filter__header'>
                <h4>{intl.messages.searchFilterLabel}</h4>

                <form method='POST' action='#' noValidate onSubmit={applyFilter}>
                    {subSubCategoryData.length > 0 && (
                        <FormControl fullWidth>
                            <FormGroup className='filter-options filter-category'>
                                <SearchFilterExpand
                                    intl={intl}
                                    data={subSubCategoryData}
                                    label='subSubCategories'
                                    checkedState={subSubCategoryChecked}
                                    handleCheckedChange={handleCheckedChange}
                                />
                            </FormGroup>
                        </FormControl>
                    )}
                    {/* temporarily hide region data
                    <FormControl fullWidth>
                        <FormGroup className='filter-options filter-region'>
                            <SearchFilterExpand
                                intl={intl}
                                data={[regionMockdata]}
                                label='region'
                                checkedState={regionChecked}
                                handleCheckedChange={handleCheckedChange}
                            />
                        </FormGroup>
                    </FormControl> */}

                    <FormControl fullWidth>
                        <FormGroup className='filter-options mb-2'>
                            <SearchFilterPrice
                                intl={intl}
                                priceRange={priceRange}
                                handlePriceChange={handlePriceChange}
                                activeRecommendedRange={activeRecommendedRange}
                                setPriceRange={setPriceRange}
                                recommededPriceRange={recommededPriceRange}
                                selectRecommendedPrice={selectRecommendedPrice}
                            />
                        </FormGroup>
                    </FormControl>

                    <FormControl fullWidth>
                        <FormGroup className='filter-options filter-rating'>
                            <section className='d-flex flex-column w-100'>
                                <p className='mb-2'>{intl.messages.rating}</p>

                                <RatingStar
                                    ratingData={ratingData}
                                    checkedState={ratingChecked}
                                    handleCheckedChange={handleCheckedChange}
                                />
                            </section>
                        </FormGroup>
                    </FormControl>

                    <div className='d-flex flex-row flex-sm-column gap-2'>
                        <button type='submit' className='form-button apply-button order-2 mb-0 mb-sm-2'>
                            <span>{intl.messages.apply}</span>
                        </button>
                        <button type='reset' className='form-button reset-button order-1' onClick={resetFilter}>
                            <span>{intl.messages.reset}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SearchFilter;
