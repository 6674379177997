import { google, otp } from 'assets/images';
import { pagePath } from 'configuration/routeConfig';
import React from 'react';
import { handleSuccessGoogleLogin, loginExternalUser } from 'features/Auth/authSlice';
import firebaseAuth from 'firebase-setup/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { handleSetSecretKey, handleSetEmail } from 'features/SetPassword/setPasswordSlice';
import { Toast } from 'utils/toast';

const AuthFooter = (props) => {
    const { dispatch, navigateTo, intl } = props;

    const handleGoogleLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const firebaseResult = await signInWithPopup(firebaseAuth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(firebaseResult);

            const loginData = {
                provider: firebaseResult.providerId,
                idToken: credential.idToken
            };

            const signInResponse = await dispatch(loginExternalUser(loginData)).unwrap();
            if (signInResponse) {
                if (signInResponse.userExisted) {
                    const timeout = () => {
                        navigateTo(pagePath.rootUrl);
                    };
                    dispatch(handleSuccessGoogleLogin(signInResponse.token));
                    setTimeout(timeout, 2000);
                } else {
                    const { success, message } = signInResponse;
                    Toast(success, message);
                    dispatch(handleSetEmail(firebaseResult.user.email));
                    dispatch(handleSetSecretKey(firebaseResult.user.accessToken));
                    navigateTo(pagePath.setPasswordUrl);
                }
            }
        } catch (err) {
            // Handle Bad Request: Toast error
            Toast(false, intl.messages.loginErrorMessage);
        }
    };

    return (
        <div className='auth-footer w-100'>
            <div className='auth-footer__or text-center mb-2'>
                <span className='px-2'>{intl.messages.or}</span>
            </div>
            <div className='auth-footer__options'>
                <div className='login-option' onClick={() => navigateTo(pagePath.loginOtpUrl)}>
                    <img src={otp} alt='otp-login' />
                    <span>{intl.messages.otpLabel}</span>
                </div>
                <div className='login-option' onClick={handleGoogleLogin}>
                    <div className='d-flex align-items-center gap-1' label='Google'>
                        <img src={google} alt='google' />
                        <span>{intl.messages.googleLabel}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthFooter;
