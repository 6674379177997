import axiosClient from './axiosClient';

const categoryApi = {
    getCategories: () => {
        const url = '/api/categories';
        return axiosClient.get(url);
    },

    getAllRelatedCategories: (id) => {
        const url = '/api/categories/getAllRelatedCategoriesId';
        return axiosClient.get(`${url}/${id}`);
    }
};

export default categoryApi;
