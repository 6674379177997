import axiosClient from "./axiosClient";

const usersApi = {
	forgotPassword: (data) => {
		const url = '/api/users/forgotPassword';
		return axiosClient.post(url, data, { params: data });
	},

	setPassword: (data) => {
		const url = '/api/users/setPassword';
		return axiosClient.put(url, data, { params: data });
	},

	registerUser: (data) => {
		const url = '/api/users';
		return axiosClient.post(url, data);
	}
};

export default usersApi;