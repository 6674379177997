import React from 'react';
import {NumericFormat} from 'react-number-format';
import CustomInput from 'components/CustomInput/CustomInput';

const SearchFilterPrice = (props) => {
    const {intl, priceRange, handlePriceChange, recommededPriceRange, activeRecommendedRange, selectRecommendedPrice} =
        props;

    return (
        <section className='d-flex flex-column w-100'>
            <p className='mb-2'>{intl.messages.priceRange}</p>

            <NumericFormat
                placeholder={intl.messages.enterPrice}
                label={intl.messages.from}
                thousandSeparator={true}
                value={priceRange.fromPrice}
                onChange={handlePriceChange}
                name='fromPrice'
                suffix={'đ'}
                className='filter-options__price mb-3'
                customInput={CustomInput}
            />

            <NumericFormat
                label={intl.messages.to}
                placeholder={intl.messages.enterPrice}
                thousandSeparator={true}
                value={priceRange.toPrice}
                onChange={handlePriceChange}
                name='toPrice'
                suffix={'đ'}
                className='filter-options__price'
                customInput={CustomInput}
            />

            <div className='d-flex d-sm-none price-list mt-2'>
                {recommededPriceRange.map((range, idx) => (
                    <div
                        key={`range-${idx}`}
                        className={`price-list__range ${activeRecommendedRange === idx ? 'active' : ''}`}
                        onClick={() => selectRecommendedPrice(range, idx)}>
                        <span>{range.from}</span>
                        {range.to && <span>k-{range.to}k</span>}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default SearchFilterPrice;
