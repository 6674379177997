const i18nLabels = {
    vi: {
        successLoginLabel: 'Đăng nhập thành công',
        successRegisterLabel: 'Đăng ký thành công',
        requiredLabel: '*',
        accountLabel: 'Tài khoản',
        accountInputLabel: 'Email, số điện thoại...',
        passwordLabel: 'Mật khẩu',
        passwordInputLabel: 'Vui lòng nhập mật khẩu của bạn',
        forgetPwLabel: 'Quên mật khẩu?',
        loginLabel: 'Đăng nhập',
        registerInfoLabel: 'Hướng dẫn kích hoạt tài khoản đã được gửi đến Email',
        nameLabel: 'Họ và tên: ',
        nameInputLabel: 'Nhập họ và tên',
        otpLabel: 'OTP',
        googleLabel: 'Google',
        phoneLabel: 'Số điện thoại',
        phoneInputLabel: 'Nhập số điện thoại',
        typeofLabel: 'Loại tài khoản',
        personalLabel: 'Cá nhân',
        companyLabel: 'Công ty',
        companyNameInputLabel: 'Nhập tên công ty',
        companyNameLabel: 'Tên công ty: ',
        emailRegisterLabel: 'Email: ',
        emailRegisterInputLabel: 'Nhập email của bạn',
        invalidEmail: 'Sai định dạng email',
        invalidPw: 'Mật khẩu phải có ít nhất 3 ký tự',
        registerButton: 'Đăng ký',
        agreeRegisterLabel: 'Khi bạn nhấn Đăng ký, bạn đã đồng ý thực hiện mọi giao dịch mua bán theo',
        policyLabel: 'điều kiện sử dụng và chính sách của Sapatco',
        registerSuccessLabel: 'Đăng ký thành công',
        registerSuccessLabel1: 'Hướng dẫn kích hoạt tài khoản đã được gửi đến Email',
        registerSuccessLabel2: 'của bạn. Vui lòng kiểm tra Email để hoàn tất đăng ký.',
        otpTextLabel: 'Vui lòng nhập mã xác thực vừa vào số điện thoại của bạn',
        saveIdLabel: 'Lưu tài khoản',
        noAccountLabel: 'Chưa có tài khoản ?',
        or: 'Hoặc',
        errorMessage: 'Đã xảy ra sự cố. Vui lòng thử lại sau giây lát',
        loginErrorMessage: 'Đăng nhập thất bại',
        signupErrorMessage: 'Đăng ký thất bại',
        checkEmail: 'Vui lòng kiểm tra email của bạn!',
        checkPhone: 'Vui lòng kiểm tra điện thoại của bạn!',
        validationError: "Các trường không được chứa khoảng trắng, vui lòng nhập lại"
    },
    en: {}
};

export default i18nLabels;
