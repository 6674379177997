export default function propsProvider(props) {
    const {
        navigateTo,
        intl,
        handleLoginSubmit,
        dispatch,
        sendOtp,
        handleSignInPhone,
        handleBack,
        handleInvalidSubmit,
        isEnteredSms,
        enteredPhone
    } = props;

    return {
        loginProps: {
            handleLoginSubmit,
            intl,
            navigateTo,
            footerProps: {
                dispatch,
                navigateTo,
                intl,
                isLogin: true
            }
        },
        otpProps: {
            sendOtp,
            dispatch,
            intl,
            navigateTo,
            handleBack,
            enteredPhone
        },
        smsProps: {
            handleSignInPhone,
            navigateTo,
            handleInvalidSubmit
        },
        isEnteredSms
    };
}
