import {handleModalPopup} from 'app/ecomSlice';
import React from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './CartPropsProvider';
import {deleteCart, deleteItemFromCart, deleteStallFromCart} from './cartSlice';
import CartMainView from './template/CartMainView';

const CartContainer = (props) => {
    const {dispatch, cart, history} = props;
    const intl = useIntl();

    const deleteItemsHandler = ({type, payload}) => {
        dispatch(handleModalPopup(true));

        try {
            if (type === 'all') {
                dispatch(deleteCart());
            } else if (type === 'stall') {
                dispatch(deleteStallFromCart(payload));
            } else if (type === 'product') {
                dispatch(deleteItemFromCart(payload));
            }
        } catch (err) {
            console.error('unexpected error');
        }
    };

    const navigateTo = (path) => {
        history.push(path);
    };

    const cartContainerProps = {
        ...props,
        dispatch,
        navigateTo,
        intl,
        deleteItemsHandler
    };

    return <CartMainView {...propsProvider(cartContainerProps)} />;
};

export default CartContainer;
