import { defaultBuilding } from 'assets/images';
import { baseEndpoint } from 'configuration/enviromentConfig';

export default function propsProvider(props) {
    const {
        intl,
        auth,
        navigateTo,
        productInfo,
        quantityDecrement,
        quantityIncrement,
        quantityDemand,
        quantitySupply,
        groupType,
        handleGroupType,
        groupPrice,
        productImgs,
        handleProductViewedImage,
        handlePreviewImage,
        isPreviewingImg,
        setIsPreviewingImg,
        previewImgCoord,
        imgPreviewScale,
        imgPreviewLength,
        imgListRef,
        handleScrollLeft,
        handleScrollRight,
        isImgListOverflowing,
        reviewPage,
        setReviewPage,
        allReviewList,
        productReviewsDetails,
        filterCategoryList,
        setFilterCategoryList,
        filterByStarList,
        setFilterByStarList,
        isSectionOverflowed,
        setIsSectionOverflowed,
        productDetails,
        currentRecommendList,
        setCurrentRecommendList,
        productId
    } = props;
    const { isLogin } = auth;
    const activeImage = productImgs.find((img) => img.isActive);
    const activeImageUrl = activeImage ? activeImage.url : '';

    const calcTotalPage = (count, pageSize) => {
        const totalPage = Math.ceil(count / pageSize);
        return isNaN(totalPage) ? 0 : totalPage;
    };

    const calcAvgRating = (reviewList, count) => {
        let sum = Array.isArray(reviewList) && reviewList.reduce((total, item) => total + item.rating, 0);
        return Math.round(sum / count);
    };
    const getDataValue = (data) => (data ? data : intl.messages.updating);

    const {
        brand_name,
        description,
        product_code,
        material,
        warranty_period,
        warranty_policy,
        extend_brand = {},
        extend_manufacturer = {}
    } = undefined || productInfo;

    const { follow, product_quantity, response_rate, response_time, logo, location } = undefined || extend_brand;
    const { name, nation } = undefined || (extend_manufacturer ? extend_manufacturer : {});

    const productShopDetails = {
        img: logo ? baseEndpoint + logo : defaultBuilding,
        shopName: getDataValue(brand_name),
        location: getDataValue(location),
        data: [
            {
                id: 'rating',
                value: 0,
                maxValue: 0,
                label: intl.messages.rating
            },
            {
                id: 'quantity',
                value: getDataValue(product_quantity),
                label: intl.messages.product,
                isHiddenOnMobile: true
            },
            {
                id: 'follow',
                value: getDataValue(follow),
                label: intl.messages.follow
            },
            {
                id: 'responseRate',
                value: getDataValue(response_rate),
                label: intl.messages.responseRate
            },
            {
                id: 'responseTime',
                value: getDataValue(response_time),
                label: intl.messages.responseTime,
                isHiddenOnMobile: true
            }
        ]
    };

    const productDetailItems = [
        { label: intl.messages.brand, value: getDataValue(name) },
        { label: intl.messages.material, value: getDataValue(material) },
        { label: intl.messages.productCode, value: getDataValue(product_code) },
        { label: intl.messages.origin, value: getDataValue(nation) },
        { label: intl.messages.warrantyPeriod, value: getDataValue(warranty_period) }
    ];

    const readMoreSectionItems = [
        { title: intl.messages.productDescription, content: description ? description : intl.messages.noDescription },
        { title: intl.messages.warrantyPolicy, content: warranty_policy ? warranty_policy : intl.messages.noPolicy }
    ];

    return {
        briefDetailProps: {
            intl,
            navigateTo,
            productInfo,
            quantityIncrement,
            quantityDecrement,
            quantityDemand,
            quantitySupply,
            groupType,
            handleGroupType,
            isLogin,
            groupPrice,
            productImgs,
            handleProductViewedImage,
            handlePreviewImage,
            isPreviewingImg,
            setIsPreviewingImg,
            previewImgCoord,
            imgPreviewScale,
            imgPreviewLength,
            imgListRef,
            activeImageUrl,
            isImgListOverflowing,
            handleScrollLeft,
            handleScrollRight
        },
        productInformationProps: {
            productShopProps: {
                intl,
                productShopDetails
            },
            productDetailProps: {
                intl,
                productDetailItems
            },
            productDescription:
                'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum, nulla quibusdam molestias reprehenderit accusamus harum! Expedita amet consectetur possimus explicabo eligendi omnis, sunt dolor sed alias dignissimos nam dolorum officia. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut, a hic possimus nihil veniam neque nam eum consectetur voluptatum repellendus minima quam tenetur debitis quae exercitationem provident eveniet, magnam ut.',
            productPolicy: intl.messages.noPolicy,
            intl,
            readMoreSectionItems,
            isSectionOverflowed,
            setIsSectionOverflowed,
            productId
        },
        addToCartProps: {
            productInfo,
        },
        feedbackProps: {
            reviewPage,
            setReviewPage,
            reviewHeaderProps: {
                totalReviews: allReviewList.length,
                avgRating: calcAvgRating(allReviewList, allReviewList.length)
            },
            reviewFilterProps: {
                filterCategoryList,
                setFilterCategoryList,
                filterByStarList,
                setFilterByStarList
            },
            reviewList: productReviewsDetails?.collection,
            totalPage: calcTotalPage(productReviewsDetails?.total, productReviewsDetails?.pagesize),
            intl
        },
        recommendProps: {
            intl,
            navigateTo,
            recommendList: productDetails.recommendDetails,
            currentRecommendList,
            setCurrentRecommendList,
            paleTitle: true
        },
        flashDealProps: {
            intl,
            navigateTo,
            flashDealList: productDetails.hotDealDetails,
            paleTitle: true
        }
    };
}
