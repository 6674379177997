import React from 'react';

import {Maintenance} from 'assets/images';

export default function CategoryItem(props) {
    const {item, navigateTo} = props;
    const {href, label, icon = undefined} = item;
    const iconSrc = icon ? icon : Maintenance;

    return (
        <li className='category-menu__item d-flex flex-column align-items-center' onClick={() => navigateTo(href)}>
            <div className='item-img'>
                <img src={iconSrc} alt={label} />
            </div>
            <p className='m-0'>{label}</p>
        </li>
    );
}
