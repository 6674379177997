import React from 'react';
import ForgetPassword from './subViews/ForgetPassword';

const ForgetPasswordMainView = (props) => {
    const {forgetPasswordProps} = props;

    return (
        <div className='forget-password'>
            <ForgetPassword {...forgetPasswordProps} />
        </div>
    );
};

export default ForgetPasswordMainView;
