export default function propsProvider(props) {
    const {navigateTo, handleInvalidSubmit, handleForgetPwValid, isSubmitted} = props;

    return {
        forgetPasswordProps: {
            navigateTo,
            handleForgetPwValid,
            handleInvalidSubmit,
            isSubmitted
        }
    };
}
