import React from 'react';
import {noFeedback} from 'assets/images';
import FeedbackHeader from './FeedbackHeader';
import FeedbackFilter from './FeedbackFilter';
import FeedbackCard from './FeedbackCard';
import FeedbackFooter from './FeedbackFooter';

const Feedback = (props) => {
    const {reviewPage, setReviewPage, reviewHeaderProps, reviewFilterProps, reviewList, totalPage} = props;

    return (
        <div className='feedback-section'>
            <FeedbackHeader {...reviewHeaderProps} />

            <FeedbackFilter {...reviewFilterProps} />

            <div className='feedback-body'>
                {!reviewList || reviewList?.length === 0 ? (
                    <div style={{textAlign: 'center', height: '295px'}}>
                        <img src={noFeedback} alt='no-feedback' style={{width: '100%', height: '100%', objectFit: 'contain'}} />
                    </div>
                ) : (
                    reviewList.map((item) => <FeedbackCard key={item.id} item={item} />)
                )}
            </div>

            {reviewList?.length > 0 && <FeedbackFooter reviewPage={reviewPage} setReviewPage={setReviewPage} totalPage={totalPage} />}
        </div>
    );
};

export default Feedback;
