import React from 'react';

const CustomButton = (props) => {
    const {children, disabled = false, ...btnProps} = props;

    return (
        <button className={`custom-btn ${disabled ? 'custom-btn--disabled' : ''}`} {...btnProps} disabled={disabled}>
            {children}
        </button>
    );
};

export default CustomButton;
