import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './headerPropsProvider';
import HeaderMainView from './template/HeaderMainView';
import {authSelector, productListSelector} from 'app/selectors';
import {useSelector} from 'react-redux';
import {handleResetRelatedCategories, handleSearchFilter} from 'features/PLP/productListSlice';
import {handleModalBackdrop} from 'app/ecomSlice';
import {useForm} from 'react-hook-form';
import {pagePath} from 'configuration/routeConfig';
import {logout} from 'features/Auth/authSlice';
import {Toast} from 'utils/toast';
const HeaderContainer = (props) => {
    const {dispatch, history, defaultValues, isInProductList, username, email, phone} = props;
    const intl = useIntl();
    const auth = useSelector(authSelector);
    const productList = useSelector(productListSelector);
    const navigateTo = (path) => {
        history.push(path);
    };
    const [dropdown, setDropdown] = useState(false);

    const showProductListSearchFilter = () => {
        dispatch(handleSearchFilter('open'));
        dispatch(handleModalBackdrop(true));
    };
    const {handleSubmit, register, reset} = useForm({defaultValues});
    const onSearchProduct = (data) => {
        const {searchValue} = data;
        reset(defaultValues);
        dispatch(handleResetRelatedCategories());
        navigateTo(pagePath.plpUrl + `?searchby=${searchValue}`);
    };
    const toogleDropdown = () => {
        setDropdown(!dropdown);
    };
    const onLogout = async () => {
        try {
            await dispatch(logout());
        } catch (err) {
            Toast(false, intl.messages.errorMessage);
        }
    };

    const headerProps = {
        ...props,
        intl,
        navigateTo,
        dispatch,
        auth,
        showProductListSearchFilter,
        productList,
        handleSubmit,
        register,
        onSearchProduct,
        isInProductList,
        toogleDropdown,
        dropdown,
        email,
        phone,
        username,
        onLogout
    };

    return <HeaderMainView {...propsProvider(headerProps)} />;
};

export default HeaderContainer;
