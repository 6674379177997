import React from 'react';

const CartTitle = (props) => {
    const {quantity = -1, intl} = props;

    return (
        <h4 className={`cart-title ${quantity >= 0 ? 'cart-title__empty' : ''}`}>
            {intl.messages.cart}
            <small className='title-extend'>
                <span>&bull;</span>
                <span>
                    {quantity} {intl.messages.product}
                </span>
            </small>
        </h4>
    );
};

export default CartTitle;
