const i18nLabels = {
    vi: {
        product: 'sản phẩm',
        goShopping: 'Tiếp tục mua hàng',
        trackingOrder: 'Theo dõi đơn hàng'
    },
    en: {}
};

export default i18nLabels;
