import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { CommentIcon, ImageIcon } from 'assets/images';
import { checkOverflow } from 'utils/checkOverflow';
import propsProvider from './productDetailsPropsProvider';
import { getAllProductReviews, getHotDeals, getProductDetails, getProductReviews, getRecommendList } from './productDetailsSlice';
import { baseEndpoint } from 'configuration/enviromentConfig';
import { checkObjectEmpty } from 'utils/checkObjectEmpty';
import ProductDetailsMainView from './template/ProductDetailsMainView';
import productReviewApi from 'api/productReviewApi';

const filterCategories = [
    {
        key: 'all',
        title: 'Tất cả',
        count: 0,
        icon: null,
        selected: true
    },
    {
        key: 'pictures',
        title: 'Hình ảnh',
        count: 0,
        icon: ImageIcon,
        selected: false
    },
    {
        key: 'comment',
        title: 'Bình luận',
        count: 0,
        icon: CommentIcon,
        selected: false
    }
];

const filterByStar = [
    {
        key: '5',
        value: 5,
        count: 0,
        selected: true
    },
    {
        key: '4',
        value: 4,
        count: 0,
        selected: false
    },
    {
        key: '3',
        value: 3,
        count: 0,
        selected: false
    },
    {
        key: '2',
        value: 2,
        count: 0,
        selected: false
    },
    {
        key: '1',
        value: 1,
        count: 0,
        selected: false
    }
];

export default function ProductDetailsContainer(props) {
    const { dispatch, i18nLabels, auth, history, productId, productDetails } = props;
    const [quantityDemand, setQuantityDemand] = useState(1);
    const [quantitySupply, setQuantitySupply] = useState(0);
    const [groupType, setGroupType] = useState([]);
    const [groupPrice, setGroupPrice] = useState({ price: 0, origin_price: 0 });
    const [productImgs, setProductImgs] = useState([]);
    const [previewImgCoord, setPreviewImgCoord] = useState({ x: 0, y: 0 });
    const [isPreviewingImg, setIsPreviewingImg] = useState(false);
    const [isImgListOverflowing, setIsImgListOverflowing] = useState(false);
    const [isSectionOverflowed, setIsSectionOverflowed] = useState(false);
    const [currentRecommendList, setCurrentRecommendList] = useState([]);
    const imgListRef = useRef(null);
    const intl = useIntl();
    //states of review section
    const [allReviewList, setAllReviewList] = useState([]);
    const [reviewPage, setReviewPage] = useState(1);
    const [filterCategoryList, setFilterCategoryList] = useState(filterCategories);
    const [filterByStarList, setFilterByStarList] = useState(filterByStar);

    const { productInfo, recommendDetails } = productDetails;
    const isProductInfoEmpty = checkObjectEmpty(productInfo);
    const productReviewsDetails = productDetails.productReviews.data;

    const imgPreviewScale = 2;
    const imgPreviewLength = 280;

    const quantityDecrement = () => setQuantityDemand(quantityDemand <= 1 ? 1 : quantityDemand - 1);
    const quantityIncrement = () => setQuantityDemand(quantityDemand + 1);
    const navigateTo = (path) => history.push(path);
    const handleScrollLeft = (el, offset) => (el.scrollLeft += offset);
    const handleScrollRight = (el, offset) => (el.scrollLeft -= offset);
    const handleGroupType = (name, id) => {
        const newGroupType = groupType.map((group) => {
            if (name === group.name) {
                return {
                    name,
                    id
                };
            }

            return group;
        });

        setGroupType(newGroupType);
    };
    const handleProductViewedImage = (url) => {
        const newProductImgs = productImgs.map((img) => {
            img.isActive = url === img.url;

            return img;
        });

        setProductImgs(newProductImgs);
    };
    const handlePreviewImage = (e) => {
        setIsPreviewingImg(true);
        const { offsetX, offsetY } = e.nativeEvent;
        const relativeX = getRealCoordinate(offsetX - imgPreviewLength),
            relativeY = getRealCoordinate(offsetY - imgPreviewLength);

        setPreviewImgCoord({ x: relativeX, y: relativeY });
    };
    const getRealCoordinate = (coord) => {
        if (coord >= 50) return 50;
        if (coord <= 0) return 0;
        return coord;
    };

    const fetchAllReviewsByProductId = async () => {
        const productReviewParams = {
            Filters: null,
            Sorts: '-created_at',
            Page: 1,
            PageSize: 10000
        };
        const res = await productReviewApi.getReviewsByProductId(productId, productReviewParams);
        const { success } = res;
        if (success) {
            setAllReviewList(res?.data?.collection);
        }
    };

    useEffect(() => {
        if (productId) {
            dispatch(getProductDetails(productId));
            fetchAllReviewsByProductId();
        }
    }, [productId]);

    useEffect(() => {
        dispatch(getRecommendList());
        dispatch(getHotDeals());
    }, []);

    useEffect(() => {
        if (allReviewList) {
            const map = new Map();

            for (let i = 0; i < allReviewList.length; i++) {
                if (map.has(allReviewList[i].rating)) {
                    map.set(allReviewList[i].rating, map.get(allReviewList[i].rating) + 1);
                } else {
                    map.set(allReviewList[i].rating, 1);
                }
            }

            const newFilterByStartList = filterByStarList.map((item) => {
                if (map.has(item.value)) {
                    item.count = map.get(item.value);
                }
                return { ...item };
            });
            setFilterByStarList(newFilterByStartList);

            const newFilterCategoryList = filterCategoryList.map((item) => {
                switch (item.key) {
                    case 'all':
                        item.count = allReviewList.length;
                        break;
                    default:
                        break;
                }
                return { ...item };
            });
            setFilterCategoryList(newFilterCategoryList);
        }
    }, [productId, allReviewList]);

    useEffect(() => {
        const filterByStarValue = filterByStarList.find((item) => item.selected);
        const productReviewParams = {
            Filters: `rating==${filterByStarValue.value}`,
            Sorts: '-created_at',
            Page: reviewPage,
            PageSize: 3
        };

        dispatch(getProductReviews({ id: productId, params: productReviewParams }));
    }, [productId, reviewPage, filterByStarList, filterCategoryList]);

    useEffect(() => {
        if (recommendDetails.length > 0) {
            setCurrentRecommendList(recommendDetails.slice(0, 16));
        }
    }, [recommendDetails]);

    useEffect(() => {
        if (!isProductInfoEmpty) {
            const { extend_type, extend_images } = productInfo;

            if (extend_type) {
                const productGroupType = extend_type.map((type) => {
                    return {
                        name: type.group_name,
                        id: type.id
                    };
                });
                setGroupType(productGroupType);
            }

            const productImgs = extend_images
                .slice()
                .sort((first, second) => first.sort_order - second.sort_order)
                .map((img, idx) => {
                    return {
                        url: `${baseEndpoint}${img.physical_path}`,
                        isActive: idx === 0
                    };
                });
            setProductImgs(productImgs);
        }
    }, [productInfo, isProductInfoEmpty]);

    useEffect(() => {
        if (!isProductInfoEmpty) {
            const { extend_product_type, quantity, price, origin_price } = productInfo;

            if (groupType.length > 0) {
                for (const product_type of extend_product_type) {
                    const { type_id, quantity, price, origin_price } = product_type;
                    let isMatchedId = true;
                    for (let i = 0; i < type_id.length && isMatchedId; i++) {
                        if (type_id[i] !== groupType[i].id) isMatchedId = false;
                    }

                    if (isMatchedId) {
                        setQuantitySupply(quantity);
                        setGroupPrice({ price, origin_price });
                    }
                }
            } else {
                setQuantitySupply(quantity);
                setGroupPrice({ price, origin_price });
            }
        }
    }, [productInfo, groupType, isProductInfoEmpty]);

    useEffect(() => {
        const handleImgListOverflow = () => {
            setIsImgListOverflowing(checkOverflow(imgListRef.current));
        };

        window.addEventListener('resize', handleImgListOverflow);

        return () => window.removeEventListener('resize', handleImgListOverflow);
    });

    const productDetailsContainerProps = {
        dispatch,
        i18nLabels,
        auth,
        navigateTo,
        productInfo,
        quantityIncrement,
        quantityDecrement,
        quantityDemand,
        quantitySupply,
        groupType,
        handleGroupType,
        groupPrice,
        productImgs,
        handleProductViewedImage,
        handlePreviewImage,
        isPreviewingImg,
        setIsPreviewingImg,
        previewImgCoord,
        imgPreviewScale,
        imgPreviewLength,
        imgListRef,
        handleScrollLeft,
        handleScrollRight,
        isImgListOverflowing,
        intl,
        allReviewList,
        reviewPage,
        setReviewPage,
        productReviewsDetails,
        filterCategoryList,
        setFilterCategoryList,
        filterByStarList,
        setFilterByStarList,
        isSectionOverflowed,
        setIsSectionOverflowed,
        productDetails,
        currentRecommendList,
        setCurrentRecommendList,
        productId
    };

    return <ProductDetailsMainView {...propsProvider(productDetailsContainerProps)} />;
}
