export default function propsProvider(props) {
    /*
            - To only list utilized props
            - Wrapping utilized props on each view
    */
    const {intl, changeLanguage, locale, footer} = props;
    const {contactInfo, appLink = {}, customerService = {}, paymentMethod = {}} = footer.data;
    const customerServiceData = Object.entries(customerService).map(([key, value]) => value);
    const paymentMethodData = Object.entries(paymentMethod).map(([key, value]) => value);
    const appInfo = Object.entries(appLink).map(([key, value]) => value);

    return {
        intl,
        changeLanguage,
        locale,
        contactInfo,
        appLink,
        customerService,
        paymentMethod,
        customerServiceData,
        paymentMethodData,
        appInfo
    };
}
