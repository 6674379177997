import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import usersApi from 'api/usersApi';
import forgetPasswordApi from 'api/usersApi';
import serviceRequest from 'app/serviceRequest';

export const registerExternalUser = createAsyncThunk(
    'register/registerExternalUser',
    async (data, {rejectWithValue}) => {
        try {
            const res = await usersApi.registerExternalUser(data);

            if (!res.success) {
                return rejectWithValue(res);
            }

            return res;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
export const resetPasswordReq = createAsyncThunk('user/reset-password', async (data,thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: forgetPasswordApi.setPassword,
        payload: data
    });
});

export const registerUser = createAsyncThunk('user/register', async (data,thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: usersApi.registerUser,
        payload: data
    });
});

const setPassword = createSlice({
    name: 'setPassword',
    initialState: {
        email: '',
        storedSecretKey: '',
        phone: ''
    },
    reducers: {
        handleSetSecretKey: (state, action) => {
            state.storedSecretKey = action.payload;
        },
        handleSetEmail: (state, action) => {
            state.email = action.payload;
        },
        handleSetPhone: (state, action) => {
            state.phone = action.payload;
        }
    },
    extraReducers: (builder) => {}
});

const {reducer, actions} = setPassword;
export const {handleSetSecretKey, handleSetEmail, handleSetPhone} = actions;
export default reducer;
