import ModalPopup from 'components/ModalPopup/ModalPopup';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import React from 'react';
import { deleteCart, handleCheckedAll } from '../cartSlice';
import CartTitle from './subViews/CartTitle/CartTitle';
import DetailCart from './subViews/DetailCart/DetailCart';
import EmptyCart from './subViews/EmptyCart/EmptyCart';

const CartMainView = (props) => {
    const { intl, emptyCartProps, cartItems, isCheckedAll, detailCartProps, dispatch } = props;
    const isCartEmpty = cartItems.length === 0;

    return (
        <div className='cart'>
            <ModalPopup intl={intl} />
            <WrapperContainer bodyContent>
                <div className='row'>
                    <div className={`col-${isCartEmpty ? 12 : 8}`}>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <CartTitle intl={intl} quantity={-1} />

                            {!isCartEmpty && (
                                <div className='d-flex gap-2'>
                                    {isCheckedAll && (
                                        <button
                                            className='form-button reset-button'
                                            onClick={() => dispatch(deleteCart())}>
                                            <span>{intl.messages.deleteAll}</span>
                                        </button>
                                    )}
                                    <button
                                        className='form-button apply-button'
                                        onClick={() => dispatch(handleCheckedAll())}>
                                        <span>
                                            {isCheckedAll ? intl.messages.deselectAll : intl.messages.selectAll}
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className='cart-content'>
                            {isCartEmpty ? <EmptyCart {...emptyCartProps} /> : <DetailCart {...detailCartProps} />}
                        </div>
                    </div>

                    {!isCartEmpty && <div className='col-4'></div>}
                </div>
            </WrapperContainer>
        </div>
    );
};

export default CartMainView;
