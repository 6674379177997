import React from 'react';
import OtpInput from 'react18-input-otp';

const CustomOtpInput = (props) => {
	return (
		<OtpInput
			inputStyle='auth-otp__input'
			shouldAutoFocus={true}
			placeholder="------"
			isInputSecure={true}
			{...props}
		/>
	);
};

export default CustomOtpInput;