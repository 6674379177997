import React, { useState } from 'react';
import { checkValidationField } from 'utils/checkValidationField';
import { Toast } from 'utils/toast';
import propsProvider from './forgetPasswordPropsProvider';
import { forgetPasswordReq } from './forgetPasswordSlice';
import ForgetPasswordMainView from './template/ForgetPasswordMainView';

const ForgetPasswordContainer = (props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { dispatch, history, intl } = props;

    const navigateTo = (path) => {
        history.push(path);
    };

    const handleForgetPwValid = async (data) => {
        if (!checkValidationField(data)) {
            Toast(false, intl.messages.validationError);
            return;
        }

        const forgetPwProps = {
            username: data.email
        };

        const res = await dispatch(forgetPasswordReq(forgetPwProps)).unwrap();
        const { success, message } = res;

        // handle toast
        if (success) {
            setTimeout(() => {
                setIsSubmitted(true);
            }, 2000);
            Toast(success, message);
        } else Toast(success, message);
    };

    const handleInvalidSubmit = (_, event) => {
        event.target?.classList.add('wasvalidated');
    };

    const forgetPasswordContainerProps = {
        navigateTo,
        dispatch,
        handleInvalidSubmit,
        handleForgetPwValid,
        isSubmitted
    };

    return <ForgetPasswordMainView {...propsProvider(forgetPasswordContainerProps)} />;
};

export default ForgetPasswordContainer;
