import React, {useEffect, useState} from 'react';

import {handleSuccessPhoneLogin} from 'features/Auth/authSlice';
import {loginPhoneNumber} from 'features/Auth/authSlice';
import {pagePath} from 'configuration/routeConfig';
import {frame2745, group35, oval, ovalMobile, phoneNumber} from 'assets/images';
import {Toast} from 'utils/toast';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import AuthModal from 'components/AuthModal/AuthModal';
import CustomOtpInput from 'components/CustomOtpInput/CustomOtpInput';
import CustomButton from 'components/CustomButton/CustomButton';
import {getAuthFirebaseErrorMessage} from 'utils/getAuthFirebaseErrorMsg';
import {handleSetPhone, handleSetSecretKey} from 'features/SetPassword/setPasswordSlice';

const AuthOTP = (props) => {
    const {dispatch, sendOtp, navigateTo, intl, handleBack, enteredPhone} = props;
    const [enteredOTP, setEnteredOTP] = useState('');
    const [timer, setTimer] = useState(60);
    const handleOTPChange = (otp) => setEnteredOTP(otp);
    const disabledButton = enteredOTP.length !== 6 || timer <= 0;

    const resendOTP = async () => {
        await sendOtp('captcha-container-resend');
        setEnteredOTP('');
        setTimer(60);
    };
    const signInWithOTP = async () => {
        try {
            const {confirmationResult} = window;
            const firebaseResult = await confirmationResult.confirm(enteredOTP);

            const signInResponse = await dispatch(loginPhoneNumber({phone: enteredPhone})).unwrap();
            const {userExisted, message, token} = signInResponse;
            if (signInResponse) {
                if (userExisted) {
                    const timeout = () => {
                        navigateTo(pagePath.rootUrl);
                    };
                    dispatch(handleSuccessPhoneLogin(token));
                    setTimeout(timeout, 2000);
                    Toast(userExisted, message);
                } else {
                    // Navigate to login page
                    const {success, message} = signInResponse;
                    Toast(success, message);
                    dispatch(handleSetPhone(enteredPhone));
                    dispatch(handleSetSecretKey(firebaseResult.user.accessToken));
                    navigateTo(pagePath.setPasswordUrl);
                }
            }
        } catch (err) {
            // handle error with Toast
            Toast(false, getAuthFirebaseErrorMessage(err.code, intl.messages.loginErrorMessage));
        }
    };

    useEffect(() => {
        if (timer > 0) {
            const timerInterval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);

            return () => clearInterval(timerInterval);
        }
    });

    return (
        <AuthLayout src1={phoneNumber} src2={group35} src3={oval} src4={frame2745} src5={ovalMobile}>
            <div className='login-right auth-otp'>
                <AuthModal>
                    <div className='login-header'>
                        <h2 className='mb-2 text-center'>{intl.messages.loginLabel}</h2>
                        <div className='m-0'>{intl.messages.otpTextLabel}</div>
                    </div>

                    <div className='otp-phone'>
                        <CustomOtpInput
                            value={enteredOTP}
                            onChange={handleOTPChange}
                            numInputs={6}
                            isInputNum
                            isDisabled={timer <= 0}
                        />

                        <p className='text-center my-3'>
                            {timer > 0 ? (
                                <span>
                                    Mã có hiệu lực trong:{' '}
                                    <span className='otp-variant'>00:{timer >= 10 ? timer : `0${timer}`}</span>
                                </span>
                            ) : (
                                <span>
                                    Không nhận được mã ?{' '}
                                    <span className='otp-variant cursor-pointer' onClick={resendOTP}>
                                        Gửi lại
                                    </span>
                                </span>
                            )}
                        </p>

                        <div className='w-100'>
                            <CustomButton type='submit' onClick={signInWithOTP} disabled={disabledButton}>
                                Gửi
                            </CustomButton>
                        </div>

                        <div className='other-option d-flex flex-column justify-content-center align-items-center gap-3 mt-4'>
                            <span onClick={handleBack}>Trở về</span>
                            <span onClick={() => navigateTo(pagePath.authUrl)}>Đăng nhập bằng phương thức khác</span>
                        </div>
                    </div>
                </AuthModal>
                <div id='captcha-container-resend'></div>
            </div>
        </AuthLayout>
    );
};

export default AuthOTP;
