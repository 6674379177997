import React from 'react';
import { ornament1, oval, ornamentMobile, ovalMobile, ornament2x } from 'assets/images';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import AuthModal from 'components/AuthModal/AuthModal';
import AuthFooter from 'components/AuthFooter/AuthFooter';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import CustomInput from 'components/CustomInput/CustomInput';
import { pagePath } from 'configuration/routeConfig';
import CustomButton from 'components/CustomButton/CustomButton';

const Login = ({ handleLoginSubmit, intl, navigateTo, footerProps }) => {
    const { control, handleSubmit, watch } = useForm({
        defaultValues: {
            username: '',
            password: ''
        }
    });
    const watchAllFields = watch();
    const checkButtonDisabled = watchAllFields.username.length === 0 || watchAllFields.password.length === 0;

    return (
        <AuthLayout src1={ornament2x} src2={ornament1} src3={oval} src4={ornamentMobile} src5={ovalMobile}>
            <div className='login-right'>
                <AuthModal>
                    <div className='login-header'>
                        <h2 className='m-0'>{intl.messages.loginLabel}</h2>
                    </div>
                    <form
                        method='post'
                        action='#'
                        className='w-100'
                        onSubmit={handleSubmit(handleLoginSubmit)}
                        noValidate>
                        <FormControl className='w-100 mb-4'>
                            <Controller
                                name='username'
                                control={control}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        label={intl.messages.accountLabel}
                                        placeholder={intl.messages.accountInputLabel}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl className='w-100 mb-3'>
                            <Controller
                                name='password'
                                control={control}
                                render={({ field }) => (
                                    <CustomInput
                                        {...field}
                                        label={intl.messages.passwordLabel}
                                        placeholder={intl.messages.passwordInputLabel}
                                        type='password'
                                        ishavingpwicon='true'
                                    />
                                )}
                            />
                        </FormControl>

                        <div className='support-container mb-1'>
                            <FormControlLabel control={<Checkbox />} label='Lưu tài khoản' />
                            <div className='forget-pw row'>
                                <div className='forget-pw-container' onClick={() => navigateTo(pagePath.forgetPasswordUrl)}>{intl.messages.forgetPwLabel}</div>
                            </div>
                        </div>

                        <div className='w-100 mb-2'>
                            <CustomButton type='submit' disabled={checkButtonDisabled}>{intl.messages.loginLabel}</CustomButton>
                        </div>

                        <div className='mb-2 no-account'>
                            {intl.messages.noAccountLabel}{' '}
                            <span className='no-account-register' onClick={() => navigateTo(pagePath.signUpUrl)}>
                                {intl.messages.registerButton}
                            </span>
                        </div>
                        <AuthFooter {...footerProps} />
                    </form>
                </AuthModal>
            </div>
        </AuthLayout>
    );
};
export default Login;
