import {createSlice} from '@reduxjs/toolkit';
import {checkObjectEmpty} from 'utils/checkObjectEmpty';

const initialState = () => {
    // const storeCart = localStorage.getItem(Config.storageKey.cart);
    // if (storeCart) {
    //     return { ...JSON.parse(storeCart) };
    // }

    return {
        isCheckedAll: false,
        storageCartItems: {
            1: {
                name: 'Gia dụng Hoàng',
                isChecked: false,
                items: {
                    1: {
                        name: 'Bồn rửa mặt Hafale',
                        sub: `Kèm vòi 800cm`,
                        price: 150000,
                        origin_price: 180000,
                        quantityInCart: 2,
                        quantityInStorage: 10,
                        isChecked: false
                    },
                    2: {
                        name: 'Bồn rửa mặt Hafale',
                        sub: `Kèm vòi 800cm`,
                        price: 150000,
                        origin_price: 180000,
                        quantityInCart: 1,
                        quantityInStorage: 10,
                        isChecked: false
                    }
                }
            },
            2: {
                name: 'Audeclothesque',
                isChecked: true,
                items: {
                    1: {
                        name: 'Áo thun unisex',
                        sub: `Màu đen Size L`,
                        price: 150000,
                        origin_price: 180000,
                        quantityInCart: 1,
                        quantityInStorage: 30,
                        isChecked: true
                    },
                    2: {
                        name: 'Quần ống loe nữ',
                        sub: `Jeans Size 38 (US)`,
                        price: 150000,
                        origin_price: 150000,
                        quantityInCart: 1,
                        quantityInStorage: 0,
                        isChecked: true
                    }
                }
            }
        }
    };
};

const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItemToCart: (state, action) => {
            const {stallId, productId, product} = action.payload;

            if (state.storageCartItems[stallId]) {
                if (
                    state.storageCartItems[stallId].items[productId] &&
                    state.storageCartItems[stallId].items[productId].quantityInCart <
                        state.storageCartItems[stallId].items[productId].quantityInStorage
                )
                    state.storageCartItems[stallId].items[productId].quantityInCart++;
                else {
                    const newProduct = {
                        name: product.name,
                        sub: product.sub,
                        price: product.price,
                        origin_price: product.origin_price,
                        quantityInStorage: product.quantity,
                        quantityInCart: 1
                    };

                    state.storageCartItems[stallId].items[productId] = newProduct;
                }
            }
        },

        deleteItemFromCart: (state, action) => {
            const {stallId, productId, isDeleteAll = false} = action.payload;

            if (state.storageCartItems[stallId] && state.storageCartItems[stallId].items[productId]) {
                if (!isDeleteAll) {
                    if (state.storageCartItems[stallId].items[productId].quantityInCart > 1) {
                        state.storageCartItems[stallId].items[productId].quantityInCart--;
                    }
                } else {
                    delete state.storageCartItems[stallId].items[productId];

                    if (checkObjectEmpty(state.storageCartItems[stallId].items)) {
                        delete state.storageCartItems[stallId];
                    }
                }
            }
        },

        changeItemQuantity: (state, action) => {
            const {stallId, productId, quantity} = action.payload;

            if (state.storageCartItems[stallId] && state.storageCartItems[stallId].items[productId]) {
                const {quantityInStorage} = state.storageCartItems[stallId].items[productId];

                state.storageCartItems[stallId].items[productId].quantityInCart =
                    quantity > quantityInStorage ? quantityInStorage : quantity < 1 ? 1 : quantity;
            }
        },

        deleteStallFromCart: (state, action) => {
            const {stallId} = action.payload;

            if (state.storageCartItems[stallId]) {
                delete state.storageCartItems[stallId];

                caseReducers.setCheckedAll(state);
            }
        },

        handleCheckedStall: (state, action) => {
            const {stallId, checkedValue = undefined, isSetCheckedAll = true} = action.payload;

            if (state.storageCartItems[stallId]) {
                const isStallChecked = checkedValue || !state.storageCartItems[stallId].isChecked;
                state.storageCartItems[stallId].isChecked = isStallChecked;

                const productKeys = Object.keys(state.storageCartItems[stallId].items);

                productKeys.forEach((key) => (state.storageCartItems[stallId].items[key].isChecked = isStallChecked));
            }

            if (isSetCheckedAll) {
                caseReducers.setCheckedAll(state);
            }
        },

        handleCheckedProduct: (state, action) => {
            const {stallId, productId} = action.payload;

            if (state.storageCartItems[stallId] && state.storageCartItems[stallId].items[productId]) {
                state.storageCartItems[stallId].items[productId].isChecked =
                    !state.storageCartItems[stallId].items[productId].isChecked;

                let isAllItemsChecked = true;

                const productKeys = Object.keys(state.storageCartItems[stallId].items);

                for (const key of productKeys) {
                    if (!state.storageCartItems[stallId].items[key].isChecked) {
                        isAllItemsChecked = false;
                        break;
                    }
                }

                state.storageCartItems[stallId].isChecked = isAllItemsChecked;

                caseReducers.setCheckedAll(state);
            }
        },

        handleCheckedAll: (state) => {
            const stallKeys = Object.keys(state.storageCartItems);

            stallKeys.forEach((stallKey) => {
                caseReducers.handleCheckedStall(state, {
                    payload: {stallId: stallKey, checkedValue: !state.isCheckedAll, isSetCheckedAll: false}
                });
            });

            caseReducers.setCheckedAll(state);
        },

        setCheckedAll: (state) => {
            let isCheckAll = true;
            const stallKeys = Object.keys(state.storageCartItems);

            for (const key of stallKeys) {
                if (!state.storageCartItems[key].isChecked) {
                    isCheckAll = false;
                    break;
                }
            }

            state.isCheckedAll = isCheckAll;
        },

        deleteCart: () => {
            return {
                isCheckedAll: false,
                storageCartItems: {}
            };
        }
    },
    extraReducers: (builders) => {}
});

const {reducer, actions, caseReducers} = cart;
export const {
    addItemToCart,
    deleteItemFromCart,
    changeItemQuantity,
    deleteStallFromCart,
    handleCheckedProduct,
    handleCheckedStall,
    handleCheckedAll,
    deleteCart
} = actions;
export default reducer;
