import React from 'react';
import moment from 'moment';
import {Rating} from '@mui/material';
import {ornament, RedHeart} from 'assets/images';
import Config from 'configuration';

const FeedbackCard = ({item}) => {
    const {extend_user, extend_product_review_attachment} = item;
    const {endPoint} = Config.apiConfig;

    return (
        <div className='feedback-card'>
            <div className='feedback-card__top'>
                <div className='user-info'>
                    <img src={extend_user.avatar ? `${endPoint}${extend_user.avatar}` : ornament} alt='user-avatar' className='user-avatar' />
                    <div className='user-info__content'>
                        <p className='user-name'>{extend_user?.full_name || 'Anonymous'}</p>
                        <Rating name='read-only' value={item.rating} size='small' readOnly />
                        <p style={{margin: 0, fontSize: '13px', color: '#999999'}}>White - 15mm</p>
                    </div>
                </div>

                <div className='rating-info'>
                    <span className='feedback-time'>{moment(item.created_at).format("HH:mm")}</span><span>{moment(item.created_at).format("DD/MM/YYYY")}</span>
                </div>
            </div>

            <div className='feedback-card__bottom'>
                <div className='feedback-content'>{item.review_text}</div>
                <div className='feedback-picture-list'>
                    {extend_product_review_attachment?.length > 0 && extend_product_review_attachment.map((item, idx) => (
                        <img key={item.id} src={`${endPoint}${item.physical_path}`} alt={`reviewImg-${idx}`} />
                    ))}
                </div>
                <div className='feedback-like'>
                    <img src={RedHeart} alt='heart' />
                    <span style={{color: '#999999', fontSize: '13px', marginLeft: '2px'}}>1</span>
                </div>
            </div>
        </div>
    );
};

export default FeedbackCard;
