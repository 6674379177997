import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCEcMqn7YSFzgINK9PRl1aJZJbWI3EaGr4",
    authDomain: "ecommerce-3574d.firebaseapp.com",
    projectId: "ecommerce-3574d",
    storageBucket: "ecommerce-3574d.appspot.com",
    messagingSenderId: "1058555325067",
    appId: "1:1058555325067:web:8c304e4f1cdc04dc93428e",
    measurementId: "G-D87CN7Y97N"
};

const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);

export default firebaseAuth;
