import React from 'react';

const WrapperContainer = (props) => {
    const {bodyContent = false, children} = props;

    return (
        <div className='container'>
            <div className={`wrapper-container ${bodyContent ? 'body-content' : ''}`}>{children}</div>
        </div>
    );
};

export default WrapperContainer;
