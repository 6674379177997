export default function propsProvider(props) {
    const {navigateTo, handleInvalidSubmit, handleSetpassword, dispatch} = props;

    return {
        navigateTo,
        handleInvalidSubmit,
        dispatch,
        handleSetpassword
    };
}
