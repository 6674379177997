import { pagePath } from 'configuration/routeConfig';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Toast } from 'utils/toast';
import { activateCodeReq } from './activateCodeSlice';

const ActivateCode = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const code = urlParams.get('code');

	useEffect(() => {
		if (code) {
			const requestActivateCode = async () => {
				const res = await dispatch(activateCodeReq({ code })).unwrap();

				const { message, success } = res;
				if (success) {
					Toast(success, "Kích hoạt tài khoản thành công");
					history.push(pagePath.loginUrl);
				} else Toast(success, message);
			};

			requestActivateCode();
		}
	}, [code]);

	return (
		<div>ActivateCode</div>
	);
};

export default ActivateCode;