import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Rating } from '@mui/material';
import { TagIcon2 } from 'assets/images';
import ProductImageCarousel from 'components/ProductImageCarousel/ProductImageCarousel';
import { pagePath } from 'configuration/routeConfig';
import { baseEndpoint } from 'configuration/enviromentConfig';

const ProductCard = (props) => {
    const { item, navigateTo } = props;
    const hasImgList = item.hasOwnProperty('extend_images') && item.extend_images.length > 0;
    const imageSourceList =
        hasImgList && item.extend_images ? item.extend_images.map((image) => `${baseEndpoint}${image.physical_path}`) : [];

    const goToPDP = () => {
        const { id } = item;
        // navigateTo(`${pagePath.pdpUrl}/${id}`);
    };

    return (
        <div className='product-card'>
            <div className='product-card__media'>
                {!hasImgList ? (
                    <img src={`${baseEndpoint}${item.default_image}`} alt={`${item.id}`} />
                ) : (
                    <ProductImageCarousel imageSourceList={imageSourceList} />
                )}
                {item.discount ? (
                    <>
                        <div className='item-tag'>
                            <img src={TagIcon2} alt='tag-icon' />
                            <p>-{item?.discount}%</p>
                        </div>
                    </>
                ) : null}
            </div>
            <div className='product-card__content' onClick={goToPDP}>
                <div className='product-card__top'>
                    <h6 className='item-title'>{item?.name}</h6>
                    <div className='item-price'>
                        <div className='item-sale-price'>
                            <FormattedNumber style='currency' currency='VND' value={item?.price} />
                        </div>
                        <div className='item-origin-price'>
                            <FormattedNumber style='currency' currency='VND' value={item?.origin_price} />
                        </div>
                    </div>
                </div>
                <div className='product-card__bottom'>
                    <div className='rating'>
                        <Rating name='read-only' value={item?.avg_rate} size='small' readOnly />
                        <p>({item?.user_rating_amount})</p>
                    </div>
                    <div className='sold-quantity'>Đã bán {item?.sold}</div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
