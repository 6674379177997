import React from 'react';
import {MdAddShoppingCart} from 'react-icons/md';

const BriefButton = (props) => {
    const {children, outline = false, variant = 'primary', startIcon = false, onClick = null} = props;

    return (
        <button className={`brief-btn ${outline ? 'brief-btn-outline' : ''} brief-btn-${variant}`} onClick={onClick}>
            {startIcon && (
                <span className='me-2'>
                    <MdAddShoppingCart fontSize={20} />
                </span>
            )}
            {children}
            <div className='btn-opacity'></div>
        </button>
    );
};

export default BriefButton;
