import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import footerApi from 'api/footerApi';
import Config from 'configuration';

const initialFooter = () => {
    if (sessionStorage.getItem(Config.storageKey.footer)) {
        return {...JSON.parse(sessionStorage.getItem(Config.storageKey.footer))};
    }
    return {
        lang: 'vi',
        data: {
            contactInfo: {},
            appLink: {},
            terms: {},
            policies: {},
            contactUs: {},
            faq: {}
        }
    };
};

export const getFooterDetails = createAsyncThunk('api/footerData', async () => {
    const res = await footerApi.getFooterData();
    return res;
});

const footer = createSlice({
    name: 'footer',
    initialState: initialFooter(),
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload;
            sessionStorage.setItem(Config.storageKey.footer, JSON.stringify(state));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFooterDetails.fulfilled, (state, action) => {
            state.data = action.payload.data;
            sessionStorage.setItem(Config.storageKey.footer, JSON.stringify(state));
        });
    }
});

const {reducer, actions} = footer;
export const {setLang} = actions;
export default reducer;
