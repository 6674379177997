import FlashDealCard from 'components/FlashDealCard/FlashDealCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import SwiperList from 'components/SwiperList/SwiperList';
import React from 'react';

const SwiperFlashDeal = (props) => {
    const {flashDealList, navigateTo, intl, paleTitle = false} = props;

    const breakpoints = {
        1200: {
            slidesPerView: 6,
            slidesPerGroup: 6
        },
        992: {
            slidesPerView: 5,
            slidesPerGroup: 5
        },
        576: {
            slidesPerView: 3,
            slidesPerGroup: 3
        }
    };

    const swiperList = flashDealList.map((item) => (
        <FlashDealCard item={item} key={item.id} navigateTo={navigateTo} intl={intl} />
    ));

    return (
        <div className='flashdeal'>
            <SectionTitle paled={paleTitle}>{intl.messages.flashDeal}</SectionTitle>

            <div className='flashdeal-list m-0 p-0'>
                <SwiperList
                    list={swiperList}
                    slidesPerView={2}
                    slidesPerGroup={2}
                    rows={1}
                    spaceBetween={16}
                    breakpoints={breakpoints}
                />
            </div>
        </div>
    );
};

export default SwiperFlashDeal;
