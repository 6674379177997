import React from 'react';
import StallBlock from './StallBlock';

const DetailCart = (props) => {
    const { cartItems, dispatch, intl } = props;

    return (
        <div className='cart-detail'>
            {cartItems.map((cartItem) => (
                <StallBlock key={cartItem.stallId} stall={cartItem} dispatch={dispatch} intl={intl} />
            ))}
        </div>
    );
};

export default DetailCart;
