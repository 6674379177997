const i18nLabels = {
    vi: {
        SKUproduct: 'SKU sản phẩm',
        quantity: 'Số lượng',
        product: 'sản phẩm',
        productLeft: 'Còn',
        requestPrice: 'Yêu cầu báo giá',
        orderNow: 'Đặt hàng ngay',
        addToCart: 'Thêm vào giỏ hàng',
        saving: 'Tiết kiệm',
        overviewDescription: 'Mô tả tổng quan sản phẩm',
        zoomImage: 'Rê vào hình để phóng to',
        shop: "Cửa hàng",
        rating: "đánh giá",
        follow: "Theo dõi",
        responseRate: "Tỉ lệ phản hồi",
        responseTime: "Thời gian phản hồi",
        brand: "Thương hiệu",
        material: "Chất liệu",
        productCode: "Mã hàng hóa",
        origin: "Xuất sứ",
        warrantyPeriod: "Hạn bảo hành",
        productDetail: "Chi tiết sản phẩm",
        productDescription: "Mô tả sản phẩm",
        warrantyPolicy: "Chính sách bảo hành và hỗ trợ",
        readMore: "Xem thêm",
        readLess: "Rút gọn",
        updating: "Đang cập nhật",
        noPolicy: "Chưa có chính sách",
        noDescription: "Chưa có mô tả",
        flashDeal: "Sản phẩm tương tự 🧐",
        recommendProduct: "Có thể bạn cũng thích"
    },
    en: {}
};

export default i18nLabels;
