import axiosClient from './axiosClient';

const footerApi = {
    getFooterData: () => {
        const url = '/api/footerDatas';
        return axiosClient.get(url);
    }
};

export default footerApi;
