import React, {useEffect} from 'react';
import propsProvider from './footerPropsProvider';
import FooterMainView from './template/FooterMainView';
import {getFooterDetails, setLang} from './footerSlice';
import {useIntl} from 'react-intl';

export default function FooterContainer(props) {
    const {dispatch, footer, customerServiceData, paymentMethodData, appInfo} = props;
    const intl = useIntl();
    const changeLanguage = (locale) => {
        dispatch(setLang(locale));
    };

    useEffect(() => {
        dispatch(getFooterDetails());
    }, []);

    const footerProps = {
        ...props,
        intl,
        changeLanguage,
        footer,
        customerServiceData,
        paymentMethodData,
        appInfo
    };

    return <FooterMainView {...propsProvider(footerProps)} />;
}
