import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Grid} from 'swiper';

import 'swiper/css/grid';
import 'swiper/css/navigation';

const SwiperList = (props) => {
    const {list, slidesPerView, slidesPerGroup, spaceBetween, rows, breakpoints} = props;

    return (
        <Swiper
            slidesPerView={slidesPerView}
            slidesPerGroup={slidesPerGroup}
            navigation={true}
            breakpoints={breakpoints}
            grid={{
                rows: rows,
                fill: 'row',
            }}
            spaceBetween={spaceBetween}
            modules={[Grid, Navigation]}
            className='mySwiper'>
            {list.map((item, idx) => (
                <SwiperSlide key={idx}>{item}</SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SwiperList;
