import {Checkbox, FormControlLabel} from '@mui/material';
import {ratingStar, ratingStarInactive} from 'assets/images';
import React from 'react';

const RatingStar = (props) => {
    const {checkedState, handleCheckedChange, ratingData = []} = props;

    return ratingData.map((rate, idx) => (
        <FormControlLabel
            key={`rating-${idx}`}
            label={
                <span>
                    {ratingData.map((_, i) => (
                        <img
                            src={i + idx < ratingData.length ? ratingStar : ratingStarInactive}
                            alt='rating'
                            key={`star-${idx}--${i}`}
                        />
                    ))}
                </span>
            }
            control={
                <Checkbox
                    value={rate}
                    checked={checkedState[idx] || false}
                    onChange={() => handleCheckedChange({type: 'rating', position: idx})}
                />
            }
            className='filter-options__label'
        />
    ));
};

export default RatingStar;
