import React from 'react';
import { pagePath } from 'configuration/routeConfig';
import { Toast } from 'utils/toast';
import propsProvider from './signupPropsProvider';
import { handleSetRegisterId, handleStoreUserInfo, registerUserByEmail } from './signupSlice';
import SignupMainView from './template/SignupMainView';
import { useIntl } from 'react-intl';
import { checkValidationField } from 'utils/checkValidationField';

const SignupContainer = (props) => {
    const { dispatch, history, signup } = props;
    const { userSignupInfo } = signup;
    const intl = useIntl();

    const navigateTo = (path) => {
        history.push(path);
    };

    const handleSignupValid = async (data) => {
        try {
            if (!checkValidationField(data)) {
                Toast(false, intl.messages.validationError);
                return;
            }

            const registerProps = {
                email: data.email,
                password: data.password
            };

            dispatch(handleStoreUserInfo(registerProps));

            const signupResponse = await dispatch(registerUserByEmail(registerProps)).unwrap();
            const { success } = signupResponse;

            // handle toast
            if (success) {
                dispatch(handleSetRegisterId(signupResponse?.data?.id));
                Toast(success, intl.messages.checkEmail);
                navigateTo(pagePath.signUpVerifyUrl);
            } else Toast(success, signupResponse?.message);
        } catch (err) {
            Toast(false, intl.messages.signupErrorMessage);
        }
    };

    const handleInvalidSubmit = (_, event) => {
        event.target?.classList.add('wasvalidated');
    };

    const signUpContainerProps = {
        navigateTo,
        handleInvalidSubmit,
        handleSignupValid,
        userSignupInfo,
        dispatch,
        intl
    };

    return <SignupMainView {...propsProvider(signUpContainerProps)} />;
};

export default SignupContainer;
