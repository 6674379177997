import React from 'react';
import {MdCheck} from 'react-icons/md';

const BriefGroupType = (props) => {
    const {group_type, activeGroupType, handleGroupType} = props;
    const {group_name, types} = group_type;

    return (
        <div key={group_name} className='group-type mb-2 d-flex align-items-center'>
            <span>{group_name}: </span>

            <div className='d-flex flex-wrap align-items-center gap-2 mb-2'>
                {types.map((type) => {
                    let isActive = false;

                    if (activeGroupType) {
                        isActive = activeGroupType.id === type.id;
                    }

                    return (
                        <div
                            className={`group-type__name ${isActive ? 'group-type__name--active' : ''}`}
                            key={type.id}
                            onClick={() => handleGroupType(group_name, type.id)}>
                            {type.name}
                            {isActive && (
                                <div className='group-type__name--checked'>
                                    <MdCheck />
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BriefGroupType;
