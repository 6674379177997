import React from 'react';
import BriefButton from './BriefButton';

const BriefButtonGroup = (props) => {
    const {intl} = props;

    return (
        <div className='border-bottom border-sm-none content-block__btn'>
            <div className='btn-row'>
                <BriefButton variant='secondary' startIcon outline>
                    {intl.messages.addToCart}
                </BriefButton>
                <BriefButton>{intl.messages.orderNow}</BriefButton>
            </div>
        </div>
    );
};

export default BriefButtonGroup;
