import React from 'react';
import i18nLabels from './footer.i18n';
import { useDispatch, useSelector } from 'react-redux';
import FooterContainer from './FooterContainer';
import DecorateContainer from 'components/DecorateContainer';
import { footerSelector } from 'app/selectors';
import { useLocation } from 'react-router-dom';

export default function Footer(props) {
    const dispatch = useDispatch();
    const footer = useSelector(footerSelector);
    const { pathname } = useLocation();
    if (pathname.includes('/user')) return null;
    const footerProps = {
        ...props,
        i18nLabels,
        dispatch,
        footer
    };
    return <DecorateContainer {...footerProps} childComponent={FooterContainer} />;
}
