import React from 'react';
import {useIntl} from 'react-intl';
import propsProvider from './ThankYouPropsProvider';
import ThankYouMainView from './template/ThankYouMainView';

const ThankYouContainer = (props) => {
    const {dispatch, history} = props;
    const intl = useIntl();

    const navigateTo = (path) => {
        history.push(path);
    };

    const thankYouContainerProps = {
        navigateTo,
        intl
    };

    return <ThankYouMainView {...propsProvider(thankYouContainerProps)} />;
};

export default ThankYouContainer;
