import React from 'react';
import Login from './subViews/Login';
import {useLocation} from 'react-router-dom';
import {pagePath} from 'configuration/routeConfig';
import AuthSMS from './subViews/AuthSMS';
import AuthOTP from './subViews/AuthOTP';

export default function AuthMainView(props) {
    const {loginProps, smsProps, otpProps, isEnteredSms} = props;
    const {pathname} = useLocation();
    let AuthComponent;

    switch (pathname) {
        case pagePath.loginUrl:
            AuthComponent = <Login {...loginProps} />;
            break;
        case pagePath.loginOtpUrl:
            AuthComponent = isEnteredSms ? <AuthOTP {...otpProps} /> : <AuthSMS {...smsProps} />;
            break;
        default:
            AuthComponent = <Login {...loginProps} />;
            break;
    }

    return <div className='auth'>{AuthComponent}</div>;
}
