import React from 'react';
import DecorateContainer from 'components/DecorateContainer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import i18nLabels from './thankYou.i18n';
import ThankYouContainer from './ThankYouContainer';

export default function ThankYou(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const thankYouProps = {
        ...props,
        dispatch,
        history,
        i18nLabels
    };
    return <DecorateContainer {...thankYouProps} childComponent={ThankYouContainer} />;
}
