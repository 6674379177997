import { authSelector, setPasswordSelector } from 'app/selectors';
import DecorateContainer from 'components/DecorateContainer';
import { pagePath } from 'configuration/routeConfig';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SetPasswordContainer from './SetPasswordContainer';
import { handleSetSecretKey } from './setPasswordSlice';
import i18nLabels from 'features/Auth/auth.i18n';

const SetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector(authSelector);
    const setPassword = useSelector(setPasswordSelector);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const secretkey = urlParams.get('secretkey'); // secretkey from set-password URL
    const { storedSecretKey } = setPassword; // secretkey from google authentication api

    // If neither of keys is existed, redirecting to the login page
    if (!secretkey && !storedSecretKey) {
        history.replace(pagePath.loginUrl);
    }

    useEffect(() => {
        if (secretkey) {
            dispatch(handleSetSecretKey(secretkey.replaceAll(' ', '+')));
        }
    }, [secretkey]);

    const signupProps = {
        dispatch,
        history,
        setPassword,
        auth,
        i18nLabels
    };

    return <DecorateContainer {...signupProps} childComponent={SetPasswordContainer} />;
};

export default SetPassword;
