import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import registerApi from 'api/registerApi';

export const activateCodeReq = createAsyncThunk('register/activate', async (data) => {
    const res = await registerApi.activateCode(data);
    return res;
});
const activateCode = createSlice({
    name: 'activateCode',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {}
});

const {reducer} = activateCode;
export default reducer;
