import React from 'react';
import ProductDetail from './ProductDetail';
import ProductShop from './ProductShop';
import ReadMoreSection from './ReadMoreSection';

const ProductInformation = (props) => {
    const {
        productShopProps,
        productDetailProps,
        readMoreSectionItems,
        intl,
        isSectionOverflowed,
        setIsSectionOverflowed,
        productId
    } = props;

    return (
        <div className='product-information'>
            <div className='row m-0'>
                {/* 
                temporarily hide this component
                <ProductShop {...productShopProps} /> 
                */}

                <div className='product-content'>
                    <div className='col-12 col-lg-6 p-0'>
                        <ProductDetail {...productDetailProps} />
                    </div>

                    <div className={`${isSectionOverflowed ? 'col-12' : 'col'} p-0 product-content__readmore`}>
                        {readMoreSectionItems.map((item) => (
                            <section key={item.title}>
                                <h4>{item.title}</h4>
                                <ReadMoreSection
                                    setIsSectionOverflowed={setIsSectionOverflowed}
                                    intl={intl}
                                    productId={productId}>
                                    {item.content}
                                </ReadMoreSection>
                            </section>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductInformation;
