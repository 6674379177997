const i18nLabels = {
    vi: {
        cart: 'Giỏ hàng',
        product: 'sản phẩm',
        emptyCart: 'Hiện chưa có sản phẩm nào 😢',
        goShopping: 'Tiếp tục mua sắm',
        selectAll: "Chọn tất cả",
        deleteAll: "Xóa tất cả",
        deselectAll: "Bỏ chọn tất cả",
        soldOut: "Hết hàng",
        left: "Còn"
    },
    en: {}
};

export default i18nLabels;
