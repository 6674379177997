import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import categoryApi from 'api/categoryApi';
import productApi from 'api/productApi';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';
const initialProductList = () => {
    if (sessionStorage.getItem(Config.storageKey.productList)) {
        return {...JSON.parse(sessionStorage.getItem(Config.storageKey.productList))};
    }
    return {
        productListDetails: {},
        showSearchFilterState: 'closed',
        subCategory: {},
        searchValueStore: '',
        relatedCategories: []
    };
};


export const getProducts = createAsyncThunk('productList/getProducts', async ({data, signal},thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: productApi.getProducts,
        payload: data,signal
    });
});

export const getAllRelatedCategories = createAsyncThunk('productList/subSubCategories', async (id,thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: categoryApi.getAllRelatedCategories,
        payload: id
    });
});

const productList = createSlice({
    name: 'productList',
    initialState: initialProductList(),
    reducers: {
        handleSearchFilter: (state, action) => {
            state.showSearchFilterState = action.payload;
        },
        removeSubSubCategory: (state, action) => {
            state.subCategory[action.payload].subSubCategories = [];
        },
        resetSubCategory: (state, action) => {
            state.subCategory = {};
        },
        storeSearchValue: (state, action) => {
            state.searchValueStore = action.payload;
        },
        handleResetRelatedCategories: (state) => {
            state.relatedCategories = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.fulfilled, (state, action) => {
                state.productListDetails = action.payload;
                sessionStorage.setItem(Config.storageKey.productList, JSON.stringify(state));
            })
            .addCase(getProducts.rejected, (state, action) => {
                console.log({state, action});
            })
            .addCase(getAllRelatedCategories.fulfilled, (state, action) => {
                state.relatedCategories = action.payload.data;
                sessionStorage.setItem(Config.storageKey.productList, JSON.stringify(state));
            })
            .addCase(getAllRelatedCategories.rejected, (state, action) => {
                console.log({state, action});
            });
        // .addCase(getSubCategories.fulfilled, (state, action) => {
        //     state.subCategory = {};
        //     action.payload.data.collection.forEach((data) => {
        //         if (!state.subCategory.hasOwnProperty(data.id)) {
        //             state.subCategory[data.id] = {name: data.name, subSubCategories: []};
        //         }
        //     });

        //     return state;
        // })
        // .addCase(getSubCategories.rejected, (state, action) => {
        //     console.log({state, action});
        // })
        // .addCase(getSubSubCategories.fulfilled, (state, action) => {
        //     const {sub_category_id} = action.payload.data.collection[0];
        //     state.subCategory[sub_category_id].subSubCategories = action.payload.data.collection;
        // })
        // .addCase(getSubSubCategories.rejected, (state, action) => {
        //     console.log({state, action});
        // });
    }
});

const {reducer, actions} = productList;
export const {
    handleSearchFilter,
    removeSubSubCategory,
    resetSubCategory,
    storeSearchValue,
    handleResetRelatedCategories
} = actions;
export default reducer;
