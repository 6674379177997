import { ratingStar, ratingStarOutline } from 'assets/images';
import React from 'react';

const FeedbackFilter = ({filterCategoryList, setFilterCategoryList, filterByStarList, setFilterByStarList}) => {
    const handleToggleFilter = (category) => {
        const tmpList = filterCategoryList.map((x) => {
            x.selected = x.key === category.key;
            return x;
        });
        setFilterCategoryList(tmpList);
    };

    const handleToggleFilterByStar = (category) => {
        const tmpList = filterByStarList.map((x) => {
            x.selected = x.key === category.key;
            return x;
        });
        setFilterByStarList(tmpList);
    };

    return (
        <div className='feedback-filter'>
            <div className='feedback-filter__top'>
                {filterCategoryList.map((category) => (
                    <div
                        key={category.key}
                        className={`feedback-category ${category.selected ? 'active' : ''}`}
                        onClick={() => handleToggleFilter(category)}>
                        {category.icon && <img src={category.icon} alt={category.key} />}
                        {category.title} ({category.count})
                    </div>
                ))}
            </div>
            <div className='feedback-filter__bottom'>
                {filterByStarList.map((ele) => (
                    <div
                        key={ele.key}
                        className={`feedback-category ${ele.selected ? 'active' : ''}`}
                        style={{gap: 0}}
                        onClick={() => handleToggleFilterByStar(ele)}>
                        {ele.key}
                        <img src={ele.selected ? ratingStar : ratingStarOutline} alt={ele.key} />
                        <span style={{color: '#999999'}}> &nbsp;- {ele.count}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeedbackFilter;
