import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import hotdealApi from 'api/hotdealApi';
import productApi from 'api/productApi';
import productReviewApi from 'api/productReviewApi';
import serviceRequest from 'app/serviceRequest';

export const getProductDetails = createAsyncThunk('product/details', async (params) => {
    
    const res = await productApi.getProductDetails(params);
    return res;
});

export const getProductReviews = createAsyncThunk('product/reviews', async ({ id, params }) => {
    const res = await productReviewApi.getReviewsByProductId(id, params);
    return res;
});

export const getRecommendList = createAsyncThunk('home/recommendList', async () => {
    const res = await productApi.getRecommendList();
    return res;
});

export const getHotDeals = createAsyncThunk('home/hotdeals', async (params) => {
    const res = await hotdealApi.getHotDeals(params);
    return res;
});

const productDetails = createSlice({
    name: 'productDetails',
    initialState: {
        productInfo: {},
        allProductReviews: {},
        productReviews: {},
        recommendDetails: [],
        hotDealDetails: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductDetails.fulfilled, (state, action) => {
                state.productInfo = action.payload.data;
            })
            .addCase(getProductDetails.rejected, (state, action) => {
                console.log({ state, action });
            })
            .addCase(getHotDeals.fulfilled, (state, action) => {
                state.hotDealDetails = action.payload.data;
            })
            .addCase(getHotDeals.rejected, (state, action) => {
                console.log({ state, action });
            })
            .addCase(getProductReviews.fulfilled, (state, action) => {
                state.productReviews = action.payload;
                console.log(action.payload);
            })
            .addCase(getProductReviews.rejected, (state, action) => {
                console.log({ state, action });
            })
            .addCase(getRecommendList.fulfilled, (state, action) => {
                state.recommendDetails = action.payload.data;
            })
            .addCase(getRecommendList.rejected, (state, action) => {
                console.log({ state, action });
            });
    }
});

const { reducer, actions } = productDetails;
export const { } = actions;
export default reducer;
