const i18nLabels = {
    vi: {
        username: 'Vo Dinh Thien',
        useremail: 'vodinhthien@gmail.com',
        login: 'Đăng nhập',
        signup: 'Đăng ký',
        firstLetter: 'DEAL',
        title: 'SỐC MỖI NGÀY',
        hotdealsPrice: '₫{price}',
        productCategoryLabel: 'Danh mục sản phẩm',
        readAll: 'Xem tất cả',
        sold: "Đã bán",
        recommendProduct: "Gợi ý sản phẩm 🧐",
        flashDeal: "Flash Deals 🔥",
        readMore: "Xem thêm"
    },
    en: {
        productCategoryLabel: 'Product Category',
        readAll: 'Read All'
    }
};

export default i18nLabels;
