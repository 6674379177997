import { authSelector, productListSelector } from 'app/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductListContainer from './ProductListContainer';
import i18nLabels from './productList.i18n';
import { useHistory, useLocation } from 'react-router-dom';
import DecorateContainer from 'components/DecorateContainer';

export default function ProductList(props) {
        const dispatch = useDispatch();
        const history = useHistory();
        const location = useLocation();
        const urlParams = new URLSearchParams(location.search);
        const productList = useSelector(productListSelector);
        const auth = useSelector(authSelector);

        const productListProps = {
                ...props,
                i18nLabels,
                dispatch,
                productList,
                auth,
                history,
                searchValue: urlParams.get('searchby'),
                searchCate: urlParams.get('cateNameId')
        };
        return <DecorateContainer {...productListProps} childComponent={ProductListContainer} />;
}
