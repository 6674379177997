import React from 'react';
import {LightBulb} from 'assets/images';

const SearchResult = ({searchValue = ''}) => {
    return (
        <div className='search-result'>
            <img src={LightBulb} alt='light-bulb' />
            <span>Kết quả tìm kiếm cho "{searchValue}"</span>
        </div>
    );
};

export default SearchResult;
