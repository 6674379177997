import React from 'react';
import {Select} from 'antd';
import {DownArrow} from 'assets/images';
import {useState} from 'react';

const sortElementListInit = [
    {
        key: 'related',
        name: 'Liên quan',
        selected: false
    },
    {
        key: 'sold',
        name: 'Bán chạy',
        selected: false
    },
    {
        key: 'created_at',
        name: 'Mới',
        selected: false
    }
];

const SortFilter = (props) => {
    const {setSortBy, setSortOrder} = props;
    const [sortElementList, setSortElementList] = useState(sortElementListInit);

    const handleChange = (value) => {
        const tempList = sortElementList.map((x) => {
            x.selected = false;
            return x;
        });
        setSortElementList(tempList);
        setSortBy('price');
        setSortOrder(value);
    };

    const handleToggleSortFilter = (ele) => {
        setSortBy(ele.key);
        setSortOrder(-1);
        const tempList = sortElementList.map((x) => {
            if (x.key === ele.key) x.selected = true;
            else x.selected = false;
            return x;
        });
        setSortElementList(tempList);
    };

    return (
        <div className='sort-filter'>
            <span className='sort-filter-label'>Sắp xếp theo:</span>
            <div className='sort-category'>
                {sortElementList.map((sortEle) => {
                    return (
                        <div
                            key={sortEle.key}
                            className={`sort-element ${sortEle.selected ? 'active' : ''}`}
                            onClick={() => handleToggleSortFilter(sortEle)}>
                            {sortEle.name}
                        </div>
                    );
                })}
                <Select
                    defaultValue={0}
                    style={{
                        width: 'auto'
                    }}
                    onChange={handleChange}
                    options={[
                        {
                            value: 0,
                            label: 'Giá thấp đến cao'
                        },
                        {
                            value: -1,
                            label: 'Giá cao đến thấp'
                        }
                    ]}
                    suffixIcon={<img src={DownArrow} alt='down-arrow' />}
                />
            </div>
        </div>
    );
};

export default SortFilter;
