import React from 'react';

const AuthLayout = (props) => {
    return (
        <div className='auth-layout overflow-hidden'>
            <div className='auth-layout__left'>
                <img src={props.src1} alt='ornament' className='img-layout img-desktop'></img>
            </div>
            <div className='auth-layout__left-tablet'>
                <img src={props.src2} alt='ornament 1' className='img-layout img-tablet-1' />
            </div>
            <div className='auth-layout__left-tablet-2'>
                <img src={props.src3} alt='oval' className='img-layout img-tablet-2' />
            </div>
            <div className='auth-layout__left-mobile'>
                <img src={props.src4} alt='ornament mobile' className='img-layout' />
            </div>
            <div className='auth-layout__left-mobile-1'>
                <img src={props.src5} alt='oval mobile' className='img-layout' />
            </div>

            <div className="auth-layout__right">
                {props.children}
            </div>
        </div>
    );
};

export default AuthLayout;