import PLP from 'features/PLP';
import PDP from 'features/PDP';
import Cart from 'features/Cart';
import NotFound from 'components/NotFound';
import Home from 'features/Home';
import Auth from 'features/Auth';
import Signup from 'features/Signup';
import SetPassword from 'features/SetPassword';
import ForgetPassword from 'features/ForgetPassword';
import ActivateCode from 'features/ActivateCode';
import ThankYou from 'features/ThankYou';

const plpUrl = '/productlist';
const pdpUrl = '/productdetails';
const cartUrl = '/cart';
const authUrl = '/user';
const loginUrl = '/user/login';
const loginOtpUrl = '/user/login/otp';
const signUpUrl = '/user/signup';
const signUpVerifyUrl = '/user/signup/otp-verify';
const setPasswordUrl = '/user/set-password';
const forgetPasswordUrl = '/user/forget-password';
const activateMailCodeUrl = '/activate';
const thankYouUrl = '/thank-you';
const rootUrl = '/';
const notFoundUrl = '/*';

const pdpUrlMatch = `${pdpUrl}/:productId`;

export const redirectList = [{from: authUrl, to: loginUrl}];

export const routeList = [
    {path: rootUrl, component: Home},
    {path: plpUrl, component: PLP},
    {path: pdpUrlMatch, component: PDP},
    {path: cartUrl, component: Cart},
    // Keep "Not found page" the last one
    {path: loginUrl, component: Auth},
    {path: loginOtpUrl, component: Auth},
    {path: setPasswordUrl, component: SetPassword},
    {path: signUpUrl, component: Signup},
    {path: signUpVerifyUrl, component: Signup},
    {path: forgetPasswordUrl, component: ForgetPassword},
    {path: activateMailCodeUrl, component: ActivateCode},
    {path: thankYouUrl, component: ThankYou},
    {path: notFoundUrl, component: NotFound}
];

export const pagePath = {
    plpUrl,
    pdpUrl,
    cartUrl,
    rootUrl,
    authUrl,
    signUpUrl,
    loginUrl,
    loginOtpUrl,
    setPasswordUrl,
    signUpVerifyUrl,
    forgetPasswordUrl,
    activateMailCodeUrl,
    thankYouUrl,
};
