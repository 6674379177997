import DecorateContainer from 'components/DecorateContainer';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ForgetPasswordContainer from './ForgetPasswordContainer';
import i18nLabels from 'features/Auth/auth.i18n';

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const forgetPasswordProps = {
        dispatch,
        history,
        i18nLabels
    };

    return <DecorateContainer {...forgetPasswordProps} childComponent={ForgetPasswordContainer} />;
};

export default ForgetPassword;
