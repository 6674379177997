export const emptyInputValidator = (value) => value.trim().length > 0;

export const emailInputValidator = (value) => {
    return String(value)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validatePhone = (input_phone) =>
{
    var phoneno = /^\(?([0-9]{10})$/;
    if(input_phone.match(phoneno)) return true
    else return false;
}


export const passwordInputValidator = (value) => value.trim().length >= 3;