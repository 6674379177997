import { addActive, addDeactive, minusActive, minusDeactive } from 'assets/images';
import React from 'react';
import { NumericFormat } from 'react-number-format';

const CustomInputNumber = (props) => {
    const {
        quantity = 1,
        decrementHandle,
        incrementHandle,
        handleQuantityChange,
        disabled = false,
        maxValue = 100,
        minValue = 1
    } = props;

    const isIncrementActive = !disabled && quantity < maxValue;
    const isDecrementActive = !disabled && quantity > minValue;

    return (
        <div className='custom-input-number'>
            <button className='control-btn control-decrease' onClick={decrementHandle} disabled={!isDecrementActive}>
                <img src={isDecrementActive ? minusActive : minusDeactive} alt='decrease by 1' />
            </button>
            <NumericFormat
                className='control-quantity'
                value={quantity}
                onChange={handleQuantityChange}
                type='number'
                max={100}
            />
            <button className='control-btn control-increase' onClick={incrementHandle} disabled={!isIncrementActive}>
                <img src={isIncrementActive ? addActive : addDeactive} alt='increase by 1' />
            </button>
        </div>
    );
};

export default CustomInputNumber;
