import banner1 from './png/img_banner_1.png';
import banner2 from './png/img_banner_2.png';
import banner3 from './png/img_banner_3.png';
import banner4 from './png/img_banner_4.png';
import banner5 from './png/img_banner_5.png';
import banner6 from './png/img_banner_6.png';
import TripleGears from './png/tripleGears.png';
import FemaleWorker from './png/femaleWorker.png';
import GooglePlay from './png/google-play.png';
import LogoFooter from './png/Logo_Footer_desktop.png';
import Avatar from './png/avatar.png';
import AppStore from './png/app-store.png';
import sapatcoLogo from './png/sapatco-logo.png';
import ornament from './png/ornament.png';
import eyes from './png/eyes-outline.png';
import phoneLogo from './png/container.png';
import google from './png/google.png';
import facebook from './png/facebook.png';
import ornament1 from './png/ornament(1).png';
import oval from './png/oval.png';
import ornamentMobile from './png/ornament-mobile.png';
import ovalMobile from './png/oval-mobile.png';
import phoneNumber from './png/phonenumber.png';
import group35 from './png/group-35.png';
import frame2745 from './png/frame-2745.png';
import approve from './png/approve.png';
import productImg from './png/product-img.png';
import productImg2 from './png/img_banner_1.png';
import eyeSee from './png/eye-see.png';
import ornament2x from './png/ornament@2x.png';
import noFeedback from './png/bg-1@2x.png';
import ornament_setPw from './png/ornament-setPw.png';
import ornament_setPw2x from './png/ornament-setPw@2x.png';
import frame2746 from './png/Frame-2746.png';
import HDLogo from './png/logo-haidanggroup.png';
import defaultBuilding from './png/default-building.png';

import mail from './svg/mail.svg';
import phone from './svg/phone.svg';
import navigation_map from './svg/navigation_map.svg';
import ChevronRight from './svg/chevron-right.svg';
import Facebook from './svg/facebook.svg';
import Gear from './svg/gear.svg';
import Instagram from './svg/instagram.svg';
import Linkedin from './svg/linkedin.svg';
import Maintenance from './svg/maintenance.svg';
import Twitter from './svg/twitter.svg';
import Youtube from './svg/youtube.svg';
import searchDocIcon from './svg/icon-doc-search.svg';
import TagIcon from './svg/tag.svg';
import TagIcon2 from './svg/tag2.svg';
import SearchIcon from './svg/searchIcon.svg';
import Cart from './svg/Cart-Outline.svg';
import cartBlue from './svg/Cart-Outline-blue.svg';
import CartActive from './svg/Cart-active.svg';
import Delivery from './svg/Delivery-Outline.svg';
import rightArrowBlue from './svg/right arrow.svg';
import ratingStar from './svg/rating-star.svg';
import ratingStarInactive from './svg/rating-star-inactive.svg';
import ratingStarOutline from './svg/rating-star-outline.svg';
import filter from './svg/filter_list.svg';
import filterInactive from './svg/filter_list_inactive.svg';
import close from './svg/Close.svg';
import LightBulb from './svg/light-bulb.svg';
import DownArrow from './svg/down-arrow.svg';
import ImageIcon from './svg/image.svg';
import CommentIcon from './svg/comment.svg';
import RedHeart from './svg/red-heart.svg';
import GrayHeart from './svg/gray-heart.svg';
import errorMark from './svg/Exclamation Mark.svg';
import location from './svg/location.svg';
import likeOutline from './svg/Like-Outline.svg';
import Share from './svg/Share.svg';
import SliderLeftArrow from './svg/sliderPrevArrow.svg';
import SliderRightArrow from './svg/sliderNextArrow.svg';
import otp from './svg/otp.svg';
import UpArrow from './svg/up-arrow.svg';
import emptyCart from "./svg/emptyCart.svg";
import addActive from "./svg/add-active.svg";
import addDeactive from "./svg/add-deactive.svg";
import minusActive from "./svg/minus-acitve.svg";
import minusDeactive from "./svg/minus-deactive.svg";
import deleteRedBtn from "./svg/delete-red-oval.svg";
import deleteGrayBtn from "./svg/delete-gray-oval.svg";
import thankYou from './svg/thankYou.svg';

import haidangLoader from './gif/loading-spiner.jpg';

export {
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    banner6,
    TripleGears,
    FemaleWorker,
    GooglePlay,
    LogoFooter,
    Avatar,
    AppStore,
    ChevronRight,
    Facebook,
    Gear,
    Instagram,
    Linkedin,
    Maintenance,
    Twitter,
    Youtube,
    sapatcoLogo,
    searchDocIcon,
    productImg,
    productImg2,
    TagIcon,
    TagIcon2,
    SearchIcon,
    Cart,
    cartBlue,
    CartActive,
    Delivery,
    mail,
    phone,
    navigation_map,
    LightBulb,
    DownArrow,
    ornament,
    eyes,
    phoneLogo,
    google,
    facebook,
    ornament1,
    oval,
    ornamentMobile,
    ovalMobile,
    phoneNumber,
    group35,
    frame2745,
    approve,
    rightArrowBlue,
    ratingStar,
    ratingStarInactive,
    eyeSee,
    ornament2x,
    filter,
    filterInactive,
    close,
    ImageIcon,
    CommentIcon,
    ratingStarOutline,
    noFeedback,
    RedHeart,
    GrayHeart,
    errorMark,
    location,
    ornament_setPw,
    ornament_setPw2x,
    likeOutline,
    Share,
    frame2746,
    SliderLeftArrow,
    SliderRightArrow,
    otp,
    HDLogo,
    defaultBuilding,
    UpArrow,
    emptyCart,
    haidangLoader,
    addActive,
    addDeactive,
    deleteGrayBtn,
    deleteRedBtn,
    minusActive,
    minusDeactive,
    thankYou

};
