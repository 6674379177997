import React from 'react';
import {location, ratingStar} from 'assets/images';

const ProductShop = (props) => {
    const {intl, productShopDetails} = props;
    const {data, img, shopName, location: shopLocation} = productShopDetails;

    return (
        <div className='product-information__shop'>
            <h4 className='mb-2'>{intl.messages.shop}</h4>

            <div className='shop-info w-100 d-flex align-items-center justify-content-between'>
                <div className='shop-info__short d-flex flex-wrap align-items-center justify-content-between'>
                    <div className='d-flex gap-2 short-overview'>
                        <img className='shop-avatar' src={img} alt='shop-logo' />

                        <div className='d-flex flex-column'>
                            <p className='m-0'>{shopName}</p>
                            <span className='d-flex align-items-center gap-1'>
                                <img src={location} alt='' />
                                {shopLocation}
                            </span>
                        </div>
                    </div>

                    <button className='shop-btn'>{intl.messages.follow}</button>
                </div>
                <div className='shop-info__rating'>
                    {data.map((shop) => (
                        <section key={shop.id} className={`${shop.isHiddenOnMobile ? 'd-none d-md-flex' : 'd-flex'}`}>
                            <span className='value d-flex align-items-center gap-1'>
                                {shop.value} {shop.id === 'rating' && <img src={ratingStar} alt={shop.id} />}
                            </span>
                            <span className='title'>
                                {shop.maxValue && shop.maxValue} {shop.label}
                            </span>
                        </section>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductShop;
