import { authSelector, homeSelector } from 'app/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomeContainer from './HomeContainer';
import i18nLabels from './home.i18n';
import { useHistory } from 'react-router-dom';
import DecorateContainer from 'components/DecorateContainer';

export default function Home(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const home = useSelector(homeSelector);
    const auth = useSelector(authSelector);

    const homeProps = {
        ...props,
        i18nLabels,
        dispatch,
        home,
        auth,
        history,
    };
    return <DecorateContainer {...homeProps} childComponent={HomeContainer} />;
}
