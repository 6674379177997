import { authErrors } from "firebase-setup/authErrors.i18n";

export const getAuthFirebaseErrorMessage = (code, defaultMsg = "") => {
	let error = defaultMsg;
	Object.entries(authErrors).forEach(([key, value]) => {
		if (code.includes(key)) {
			error = value;
		}
	});

	return error;
};