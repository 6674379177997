import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import React from 'react';
import SearchResult from './subViews/SearchResult/SearchResult';
import SortFilter from './subViews/SortFilter/SortFilter';
import ProductList from './subViews/ProductList/ProductList';
import CustomPagination from 'components/Pagination/Pagination';
import SearchFilter from './subViews/SearchFilter/SearchFilter';
import ModalBackdrop from 'components/ModalBackdrop/ModalBackdrop';

export default function ProductListMainView(props) {
    const { productListProps, searchFilterProps, searchResultValue } = props;
    const { totalPage, currentPage, setPage, setSortBy, setSortOrder } = productListProps;

    return (
        <div className='product-page'>
            <ModalBackdrop />
            <WrapperContainer bodyContent>
                <div className='row m-0'>
                    <div className='col-12 col-sm-3 col-xl-2 p-0'>
                        <SearchFilter {...searchFilterProps} />
                    </div>

                    <div className='col-12 col-sm-9 col-xl-10 p-0'>
                        <SearchResult searchValue={searchResultValue} />
                        <SortFilter setSortBy={setSortBy} setSortOrder={setSortOrder} />
                        <ProductList {...productListProps} />
                        <div className='pagination'>
                            <CustomPagination totalPage={totalPage} currentPage={currentPage} setPage={setPage} />
                        </div>
                    </div>
                </div>
            </WrapperContainer>
        </div>
    );
}
