const i18nLabels = {
    vi: {
        contactLabel: 'Thông tin chi tiết',
        serviceLabel: 'Chăm sóc khách hàng',
        paymentLabel: 'Phương thức thanh toán',
        appDownloadLabel: 'Tải ứng dụng tại',
        langVietnam: 'Tiếng Việt',
        langEnglish: 'English',
    },
    en: {
        contactLabel: 'Contact information',
        serviceLabel: 'Customer service',
        paymentLabel: 'Payment method',
        appDownloadLabel: 'Download app at',
    }
};

export default i18nLabels;
