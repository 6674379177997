import {configureStore} from '@reduxjs/toolkit';
import productListReducer from 'features/PLP/productListSlice';
import productDetailsReducer from 'features/PDP/productDetailsSlice';
import authReducer from 'features/Auth/authSlice';
import signupReducer from 'features/Signup/signupSlice';
import footerReducer from 'components/Footer/footerSlice';
import homeReducer from 'features/Home/homeSlice';
import setPasswordReducer from 'features/SetPassword/setPasswordSlice';
import cartReducer from 'features/Cart/cartSlice';
import ecomReducer from './ecomSlice';
import loaderReducer from './loaderSlice';

const rootReducer = {
    home: homeReducer,
    productList: productListReducer,
    productDetails: productDetailsReducer,
    auth: authReducer,
    signup: signupReducer,
    footer: footerReducer,
    setPassword: setPasswordReducer,
    ecom: ecomReducer,
    cart: cartReducer,
    loader: loaderReducer
};
const store = configureStore({reducer: rootReducer});
export default store;
