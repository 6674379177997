import React from 'react';

import i18nLabels from './header.i18n';
import DecorateContainer from 'components/DecorateContainer';
import {useDispatch} from 'react-redux';
import HeaderContainer from './HeaderContainer';
import {useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

export default function Header(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {pathname} = useLocation();
    if (pathname.includes('/user')) return null;
    const isInProductList = pathname === '/productlist';
    const headerProps = {
        ...props,
        i18nLabels,
        dispatch,
        history,
        isInProductList
    };

    return <DecorateContainer {...headerProps} childComponent={HeaderContainer} />;
}
