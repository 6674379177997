import { Checkbox } from '@mui/material';
import { deleteGrayBtn } from 'assets/images';
import CustomInputNumber from 'components/CustomInputNumber/CustomInputNumber';
import { addItemToCart, changeItemQuantity, deleteItemFromCart, handleCheckedProduct } from 'features/Cart/cartSlice';
import React from 'react';
import { FormattedNumber } from 'react-intl';

const ProductBlock = (props) => {
    const { product, stallId, dispatch, intl } = props;
    const {
        productId,
        name: productName,
        price,
        quantityInCart,
        quantityInStorage,
        origin_price,
        sub,
        isChecked
    } = product;

    const isDisabled = quantityInStorage <= 0;
    const isQuantityLowerThan10 = quantityInStorage <= 10;

    return (
        <div
            className={`cart-detail__product ${isChecked ? 'product-checked' : ''} ${isDisabled ? 'product-disabled' : ''
                }`}>
            <Checkbox
                name={productName}
                id={stallId}
                value={stallId}
                checked={isDisabled ? false : isChecked}
                onChange={() => dispatch(handleCheckedProduct({ stallId, productId }))}
                disabled={isDisabled}
            />
            <div className='product-block'>
                <div className='product-block__detail'>
                    <div className='product-img'>
                        <img
                            src='https://images.unsplash.com/photo-1609210884848-2d530cfb2a07?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=443&q=80'
                            alt={productName}
                        />
                    </div>
                    <div className='product-info'>
                        <div className='product-info__primary'>
                            <p className='m-0'>{productName}</p>
                            <small>{sub}</small>
                        </div>
                        <div className='product-info__price'>
                            <p className='current-price'>
                                <FormattedNumber style='currency' currency='VND' value={price} />
                            </p>
                            {origin_price !== price && (
                                <p className='origin-price'>
                                    <FormattedNumber style='currency' currency='VND' value={origin_price} />
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <div className='product-block__quantity'>
                    <CustomInputNumber
                        quantity={quantityInCart}
                        decrementHandle={() => dispatch(deleteItemFromCart({ stallId, productId }))}
                        incrementHandle={() => dispatch(addItemToCart({ stallId, productId }))}
                        handleQuantityChange={(e) =>
                            dispatch(changeItemQuantity({ stallId, productId, quantity: parseInt(e.target.value) }))
                        }
                        disabled={isDisabled}
                        maxValue={quantityInStorage}
                    />

                    {isQuantityLowerThan10 && (
                        <div className='quantity-noti'>
                            {!isDisabled
                                ? `${intl.messages.left} ${quantityInStorage} ${intl.messages.product}`
                                : intl.messages.soldOut}
                        </div>
                    )}
                </div>

                <div className='product-block__price'>
                    <span>
                        <FormattedNumber style='currency' currency='VND' value={price * quantityInCart || 0} />
                    </span>
                    <button
                        className='product-delete'
                        onClick={() => dispatch(deleteItemFromCart({ stallId, productId, isDeleteAll: true }))}>
                        <img src={deleteGrayBtn} alt='delete product' />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductBlock;
