import Config from 'configuration';

export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
*/
    const {
        intl,
        setIsLogin,
        dispatch,
        navigateTo,
        auth,
        showProductListSearchFilter,
        productList,
        handleSubmit,
        register,
        onSearchProduct,
        isInProductList,
        toogleDropdown,
        dropdown,
        onLogout
    } = props;

    const isLogin = auth.isLogin;
    const username = auth.userLoginInfo?.full_name;
    const avatar = auth.userLoginInfo?.avatar;
    const email = auth.userLoginInfo?.email;
    const phone = auth.userLoginInfo?.phone;
    const config = Config.apiConfig.endPoint;
    const {showSearchFilterState} = productList;
    const defaultValues = {searchValue: ''};

    return {
        intl,
        isLogin,
        dispatch,
        navigateTo,
        setIsLogin,
        auth,
        username,
        avatar,
        config,
        showProductListSearchFilter,
        showSearchFilterState,
        handleSubmit,
        register,
        onSearchProduct,
        isInProductList,
        defaultValues,
        toogleDropdown,
        dropdown,
        email,
        phone,
        onLogout

    };
}
