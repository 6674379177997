export default function propsProvider(props) {
    /*
        - To only list utilized props
        - Wrapping utilized props on each view
    */
    const {
        intl,
        i18nLabels,
        productList,
        navigateTo,
        page,
        setPage,
        setSortBy,
        setSortOrder,
        searchValue,
        cateName,
        activeRecommendedRange,
        selectRecommendedPrice,
        handlePriceChange,
        handleCheckedChange,
        resetFilter,
        applyFilter,
        recommededPriceRange,
        setSubSubCategoryChecked,
        searchFilterRef,
        closingSearchFilter,
        subSubCategoryChecked,
        regionChecked,
        priceRange,
        setPriceRange,
        ratingData,
        ratingChecked
    } = props;
    const {showSearchFilterState} = productList;
    const searchResultValue = cateName ? cateName : searchValue;

    const calcTotalPage = (count, pageSize) => {
        const totalPage = Math.ceil(count / pageSize);
        return isNaN(totalPage) ? 0 : totalPage;
    };

    return {
        navigateTo,
        i18nLabels,
        searchResultValue,
        productListProps: {
            intl,
            i18nLabels,
            navigateTo,
            productList: productList.productListDetails?.data?.collection || [],
            totalPage: calcTotalPage(
                productList.productListDetails?.data?.total,
                productList.productListDetails?.data?.pagesize
            ),
            currentPage: page,
            setPage: setPage,
            setSortBy: setSortBy,
            setSortOrder: setSortOrder
        },
        searchFilterProps: {
            intl,
            showSearchFilterState,
            activeRecommendedRange,
            selectRecommendedPrice,
            handlePriceChange,
            handleCheckedChange,
            resetFilter,
            applyFilter,
            recommededPriceRange,
            setSubSubCategoryChecked,
            searchFilterRef,
            closingSearchFilter,
            subSubCategoryChecked,
            regionChecked,
            priceRange,
            setPriceRange,
            ratingData,
            ratingChecked
        }
    };
}
