import React from 'react';
import {baseEndpoint} from 'configuration/enviromentConfig';
import {FormattedNumber} from 'react-intl';
import { cartBlue } from 'assets/images';

const AddToCart = (props) => {
    const {productInfo} = props;
    const {name, default_image, price} = productInfo;

    return (
        <div className='add-to-cart'>
            <div className='product-img'>
                <img src={`${baseEndpoint}${default_image}`} alt='product' />
            </div>
            <div className='product-info'>
                <div className='product-info__left'>
                    <h5 className='product-title'>{name}</h5>
                </div>
                <div className='product-info__right'>
                    <h3 className='product-price'>
                        <FormattedNumber style='currency' currency='VND' value={price} />
                    </h3>
                </div>
            </div>
            <div className='verticle-divider'></div>
            <div className='action-buttons'>
                <button className='purchase-button'>Mua ngay</button>
                <button className='add-button'><img src={cartBlue} alt='cart'/>Thêm vào giỏ hàng</button>
            </div>
        </div>
    );
};

export default AddToCart;
