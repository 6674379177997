import React, { useState } from 'react';
import CustomPagination from 'components/Pagination/Pagination';
// import { Select } from 'antd';
// import { DownArrow } from 'assets/images';

const FeedbackFooter = (props) => {
    const {reviewPage, setReviewPage, totalPage} = props;
    // const [page, setPage] = useState(1);
    // const [pageSize, setPageSize] = useState(5);

    // const handleChangePageSize = (value) => {
    //     setPageSize(value);
    // };

    return (
        <div className='feedback-footer'>
            <CustomPagination totalPage={totalPage} currentPage={reviewPage} setPage={setReviewPage} />
            {/* <div className='d-flex justify-content-end align-items-center'>
                <span style={{marginRight: '16px', fontSize: '13px', fontFamily: 'inherit', color: '#b3b3b3'}}>
                    Số dòng trên trang
                </span>
                <Select
                    defaultValue={pageSize}
                    style={{
                        width: 45
                    }}
                    onChange={handleChangePageSize}
                    options={[
                        {
                            value: 5,
                            label: '05'
                        },
                        {
                            value: 10,
                            label: '10'
                        },
                        {
                            value: 15,
                            label: '15'
                        }
                    ]}
                    suffixIcon={<img src={DownArrow} alt='arrow' />}
                    dropdownStyle={{padding: 0}}
                />
            </div> */}
        </div>
    );
};

export default FeedbackFooter;
