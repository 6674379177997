const i18nLabels = {
    vi: {
        searchLabel: 'Tìm kiếm sản phẩm',
        userName: 'Hi, Võ Đình Thiên',
        login: 'Đăng nhập',
        signup: 'Đăng ký',
        filter: 'Lọc',
        signout: 'Đăng xuất',
        errorMessage: 'Đã xảy ra sự cố. Vui lòng thử lại sau giây lát'
    },
    en: {
        searchLabel: 'Search part name, part code, SKU...',
        userName: 'Hi, Võ Đình Thiên',
        filter: 'filter',
    }
};

export default i18nLabels;
