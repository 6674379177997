import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import registerApi from 'api/registerApi';
import Config from 'configuration';
import serviceRequest from 'app/serviceRequest';

export const registerUserByEmail = createAsyncThunk('user/register', async (data,thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: registerApi.registerUserByEmail,
        payload: data
    });
});

export const registerUserByPhone = createAsyncThunk('user/registerByPhone', async (data,thunkAPI) => {
    return serviceRequest({
        dispatch: thunkAPI.dispatch,
        serviceMethod: registerApi.registerUserByPhone,
        payload: data
    });
});

const signup = createSlice({
    name: 'signup',
    initialState: {
        userSignupInfo: {}
    },
    reducers: {
        handleStoreUserInfo: (state, action) => {
            state.userSignupInfo = action.payload;
        },
        handleSetRegisterId: (state, action) => {
            localStorage.removeItem(Config.storageKey.registerId);
            localStorage.setItem(Config.storageKey.registerId, action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(registerUserByEmail.fulfilled, (state, action) => {});
        builder.addCase(registerUserByPhone.fulfilled, (state, action) => {});
    }
});

const {reducer} = signup;
export const {handleStoreUserInfo, handleSetRegisterId} = signup.actions;
export default reducer;
