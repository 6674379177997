import {ecomSelector} from 'app/selectors';
import React from 'react';
import {useSelector} from 'react-redux';

const ModalBackdrop = (props) => {
    const {isShowModalBackdrop} = useSelector(ecomSelector);

    return <div className={`modal-backdrop ${isShowModalBackdrop ? 'show-modal' : ''}`}></div>;
};

export default ModalBackdrop;
