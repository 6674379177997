import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './_styles.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {redirectList, routeList} from 'configuration/routeConfig';
import {Provider} from 'react-redux';
import store from 'app/store';
import {ToastContainer} from 'react-toastify';
import ScrollToTop from 'utils/hooks/ScrollToTop';
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton';
import Loader from 'components/Loader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Router>
            <ScrollToTop />
            <Header />
            <Loader />
            <Switch>
                {redirectList.map((redirect, idx) => (
                    <Redirect key={`redirect-${idx}`} exact from={redirect.from} to={redirect.to} />
                ))}
                {routeList.map((route, idx) => (
                    <Route key={`route-${idx}`} exact path={route.path} component={route.component} />
                ))}
            </Switch>
            <Footer />
            <ToastContainer theme='colored' />
            <ScrollToTopButton />
        </Router>
    </Provider>
);
