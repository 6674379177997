import React, {useState} from 'react';
import {UpArrow} from 'assets/images';

const ScrollToTopButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 500) setVisible(true);
        else setVisible(false);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <button className={`scroll-button ${visible ? 'visibled' : ''}`} onClick={scrollToTop}>
            <img src={UpArrow} alt='up-arrow' />
        </button>
    );
};

export default ScrollToTopButton;
