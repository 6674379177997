import React from 'react';
import {useLocation} from 'react-router-dom';
import {pagePath} from 'configuration/routeConfig';
import Signup from './subViews/Signup';
import SignupByEmailOtp from './subViews/SignupByEmailOtp';

const SignupMainView = (props) => {
    const {signupProps, signupOtpProps} = props;
    const {pathname} = useLocation();
    let SignupComponent;

    switch (pathname) {
        case pagePath.signUpUrl:
            SignupComponent = <Signup {...signupProps} />;
            break;
        case pagePath.signUpVerifyUrl:
            SignupComponent = <SignupByEmailOtp {...signupOtpProps} />;
            break;
        default:
            SignupComponent = <Signup {...signupProps} />;
            break;
    }

    return (
        SignupComponent
    );
};

export default SignupMainView;
