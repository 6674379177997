import axiosClient from './axiosClient';

const authApi = {
    authenticate: (data) => {
        const url = '/api/authenticate';
        return axiosClient.post(url, data);
    },
    getMyInfo: (data) => {
        const url = '/api/authenticate/getMyInfo';
        return axiosClient.get(url, data);
    },
    loginExternalUser: (data) => {
        const url = '/api/authenticate/externalLogin';
        return axiosClient.post(url, data, { params: data });
    },
    loginPhoneNumber: (data) => {
        const url = '/api/authenticate/phoneNumberLogin';
        return axiosClient.post(url, data, { params: data });
    },
    logout: () => {
        const url = '/api/authenticate/logout';
        return axiosClient.post(url);
    }
};

export default authApi;
