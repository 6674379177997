import {createSlice} from '@reduxjs/toolkit';

const initEcomState = {
    isShowModalBackdrop: false,
    isShowModalPopup: false
};

const ecom = createSlice({
    name: 'ecom',
    initialState: initEcomState,
    reducers: {
        handleModalBackdrop: (state, action) => {
            state.isShowModalBackdrop = action.payload;
        },
        handleModalPopup: (state, action) => {
            state.isShowModalPopup = action.payload;
        }
    }
});

const {actions, reducer} = ecom;
export const {handleModalBackdrop, handleModalPopup} = actions;
export default reducer;
