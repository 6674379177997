import React from 'react';
import {useLocation} from 'react-router';

const ButtonIcon = (props) => {
    const {icon, activeIcon, quantity = 0, label = '', ...restProps} = props;
    const location = useLocation();
    const isButtonActive = location.pathname.slice(1) === label;

    return (
        <div className={`button-icon ${isButtonActive ? 'button-icon--active' : ''}`} {...restProps}>
            <img src={isButtonActive ? activeIcon : icon} alt={label} />
            {quantity > 0 && <span className='button-icon__quantity'>{quantity > 99 ? '99+' : quantity}</span>}
        </div>
    );
};

export default ButtonIcon;
