import axiosClient from './axiosClient';

const productApi = {
    getProducts: (data, signal) => {
        const url = '/api/products';
        return axiosClient.get(url, {params: data, signal});
    },

    getRecommendList: () => {
        const url = '/api/products/recommendProductList';
        return axiosClient.get(url);
    },

    getProductDetails: (id) => {
        const url = '/api/products';
        return axiosClient.get(`${url}/${id}`);
    }
};

export default productApi;
