import {Carousel} from 'react-bootstrap';

function ProductImageCarousel(props) {
    const {imageSourceList} = props;

    return (
        <Carousel interval={null} wrap={false}>
            {(imageSourceList || []).map((imageSrc, idx) => (
                <Carousel.Item key={idx}>
                    <img
                        className='d-block w-100'
                        src={imageSrc}
                        alt={`${idx}`}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default ProductImageCarousel;
