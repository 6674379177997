import { Rating } from '@mui/material';
import React from 'react';

const FeedbackHeader = (props) => {
    const {totalReviews, avgRating} = props;

    return (
        <div className='feedback-header'>
            <p>Đánh giá sản phẩm</p>
            <div className='feedback-header__right'>
                <span className='rating-amount'>{totalReviews} đánh giá</span>
                <Rating name='read-only' value={!isNaN(avgRating) ? avgRating : 0} size='small' readOnly />
                <span className='rating-average'>{!isNaN(avgRating) ? avgRating : 0}</span>
            </div>
        </div>
    );
};

export default FeedbackHeader;
