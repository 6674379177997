import {Checkbox, FormControlLabel} from '@mui/material';
import {deleteRedBtn} from 'assets/images';
import {deleteStallFromCart, handleCheckedStall} from 'features/Cart/cartSlice';
import React from 'react';
import ProductBlock from './ProductBlock';

const StallBlock = (props) => {
    const {stall, dispatch, intl} = props;
    const {stallId, stallName, items, isChecked = false} = stall;

    return (
        <div className={`cart-detail__stall ${isChecked ? 'stall-checked' : ''}`}>
            <div className='stall-header'>
                <div className='stall-header__title'>
                    <FormControlLabel
                        label={stallName}
                        control={
                            <Checkbox
                                name={stallName}
                                id={stallId}
                                value={stallId}
                                checked={isChecked}
                                onChange={() => dispatch(handleCheckedStall({stallId}))}
                            />
                        }
                    />
                </div>
                <button className='stall-header__delete' onClick={() => dispatch(deleteStallFromCart({stallId}))}>
                    <img src={deleteRedBtn} alt='delete stall' />
                </button>
            </div>
            {items.map((item) => (
                <ProductBlock product={item} key={item.productId} stallId={stallId} dispatch={dispatch} intl={intl} />
            ))}
        </div>
    );
};

export default StallBlock;
