import React from 'react';
import { SearchIcon, HDLogo, Cart, Delivery, filter, filterInactive, CartActive } from 'assets/images';
import { Button } from '@mui/material';
import ButtonIcon from 'components/ButtonIcon';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import { pagePath } from 'configuration/routeConfig';

const HeaderMainView = (props) => {
    const {
        isLogin,
        intl,
        navigateTo,
        username,
        avatar,
        config,
        showProductListSearchFilter,
        showSearchFilterState,
        handleSubmit,
        register,
        onSearchProduct,
        isInProductList,
        toogleDropdown,
        dropdown,
        email,
        phone,
        onLogout
    } = props;
    const isShowSearchFilter = showSearchFilterState === 'open';

    return (
        <div className='header'>
            <WrapperContainer>
                <div className='d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between'>
                    <div className='header-logo'>
                        <img
                            src={HDLogo}
                            alt='logo'
                            className='img-fluid'
                            onClick={() => navigateTo(pagePath.rootUrl)}
                        />
                    </div>
                    <div className='header-search'>
                        <form className='w-100 h-100' onSubmit={handleSubmit(onSearchProduct)} autoComplete='false'>
                            <input
                                type='search'
                                placeholder={intl.messages.searchLabel}
                                {...register('searchValue')}
                                autoComplete='false'
                            />
                            <button type='submit' className='search-icon'>
                                <img src={SearchIcon} alt='search' />
                            </button>
                        </form>
                        {isInProductList && (
                            <div
                                className='header-search__filter d-flex d-sm-none'
                                onClick={showProductListSearchFilter}>
                                <img src={isShowSearchFilter ? filterInactive : filter} alt='filter' />
                                <span className={isShowSearchFilter ? 'inactive' : ''}>{intl.messages.filter}</span>
                            </div>
                        )}
                    </div>
                    <div className='header-account d-flex align-items-center'>
                        {isLogin ? (
                            <>
                                <ButtonIcon
                                    icon={Cart}
                                    activeIcon={CartActive}
                                    // onClick={() => navigateTo(pagePath.cartUrl)}
                                    label='cart'
                                />
                                <ButtonIcon icon={Delivery} label='delivery' />
                                <div
                                    className='header-account__info d-flex align-items-center gap-2'
                                    onClick={() => toogleDropdown()}>
                                    <img src={`${config}${avatar}`} alt='' className='account-avatar' />
                                    <div>
                                        <div className='account-name'>
                                            <span>{username ? username : email ? email : phone}</span>
                                        </div>
                                    </div>
                                    <div className={dropdown ? 'dropdown' : 'd-none'}>
                                        <ul>
                                            <li>Tài khoản của tôi</li>
                                            <li>Đơn Hàng</li>
                                            <li onClick={() => onLogout()}>{intl.messages.signout}</li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <Button
                                    className='header-account__signup'
                                    onClick={() => navigateTo(pagePath.signUpUrl)}>
                                    {intl.messages.signup}
                                </Button>
                                <Button
                                    variant='outlined'
                                    className='header-account__login'
                                    onClick={() => navigateTo(pagePath.authUrl)}>
                                    {intl.messages.login}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </WrapperContainer>
        </div>
    );
};

export default HeaderMainView;
