import ExpandProductList from 'components/ExpandProductList/ExpandProductList';
import SwiperFlashDeal from 'components/SwiperFlashDeal/SwiperFlashDeal';
import WrapperContainer from 'components/WrapperContainer/WrapperContainer';
import React from 'react';
import AddToCart from './subViews/AddToCart/AddToCart';
import BriefDetail from './subViews/BriefDetail/BriefDetail';
import Feedback from './subViews/FeedbackSection/Feedback';
import ProductInformation from './subViews/ProductInformation/ProductInformation';

export default function ProductDetailsMainView(props) {
    const {briefDetailProps, productInformationProps, feedbackProps, recommendProps, flashDealProps, addToCartProps} = props;

    return (
        <div className='product-details'>
            <WrapperContainer bodyContent>
                {/* <div className='product-details__info'>
                    <BriefDetail {...briefDetailProps} />
                </div> */}
                <div className='row m-0 flex-column gap-3'>
                    <section className='product-details__section col-12 p-0'>
                        <ProductInformation {...productInformationProps} />
                    </section>
                    <section className='product-details__section col-12 p-0'>
                        <Feedback {...feedbackProps} />
                    </section>
                    <section>
                        <SwiperFlashDeal {...flashDealProps} />
                    </section>
                    <section>
                        <ExpandProductList {...recommendProps} />
                    </section>
                </div>
                <section className='mini-card'>
                    <AddToCart {...addToCartProps}/>
                </section>
            </WrapperContainer>
        </div>
    );
}
