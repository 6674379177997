import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { ecomSelector } from 'app/selectors';

export default function ModalPopup(props) {
    const { cancelHandle, approveHandle, intl } = props;
    const ecom = useSelector(ecomSelector);
    const { isShowModalPopup } = ecom;

    return ReactDOM.createPortal(
        <Dialog
            open={isShowModalPopup}
            onClose={cancelHandle}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{intl.messages.confirm}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Let Google help apps determine location. This means sending anonymous location data to Google, even
                    when no apps are running.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelHandle}>Disagree</Button>
                <Button onClick={approveHandle} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>,
        document.getElementById('modal')
    );
}
