import axiosClient from './axiosClient';

const registerApi = {
	registerUserByEmail: (data) => {
		const url = '/api/registeredUsers/registerByEmail';
		return axiosClient.post(url, data);
	},
	activateCode: (data) => {
		const url = '/api/registeredUsers/activate';
		return axiosClient.put(url, data, { params: data });
	},
	registerExternalUser: (data) => {
		const url = '/api/registeredUsers/registerExternalUser';
		return axiosClient.post(url, data);
	},
	registerUserByPhone: (data) => {
		const url = '/api/registeredUsersByPhone';
		return axiosClient.post(url, data);
	},
	registerUserOTPConfirm: (data) => {
		const url = `/api/registeredUsers/OTPConfirm?register_user_id=${data.register_user_id}&otp=${data.otp}`;
		return axiosClient.post(url);
	},
};

export default registerApi;
