import environmentConfig, { baseEndpoint } from './enviromentConfig';
import { storageKey } from './storageKey';

const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const envConfig = isDevEnv ? environmentConfig.development : environmentConfig.production;

const locationCompanyUrl = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15673.978280066998!2d106.715534!3d10.849938!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5b23183ec9613078!2zQ1RZIFROSEggQ8OUTkcgTkdI4buGIFBI4bukIFTDmU5HIFPDgEkgR8OSTiBTQVBBVENP!5e0!3m2!1svi!2sus!4v1659947327656!5m2!1svi!2sus';
const iconEndpoint = 'assets/media/icons/';


const Config = {
    isDevEnv,
    storageKey,
    locationCompanyUrl,
    baseEndpoint,
    iconEndpoint,
    apiConfig: {
        ...envConfig
    }
};

export default Config;
