export const homeSelector = (state) => state.home;

export const productListSelector = (state) => state.productList;

export const productDetailsSelector = (state) => state.productDetails;

export const authSelector = (state) => state.auth;

export const footerSelector = (state) => state.footer;

export const setPasswordSelector = (state) => state.setPassword;

export const ecomSelector = (state) => state.ecom;

export const signupSelector = (state) => state.signup;

export const cartSelector = (state) => state.cart;

export const loaderSelector = (state) => state.loader;
