import ProductCard from 'components/ProductCard/ProductCard';
import React from 'react';

const ProductList = (props) => {
    const {productList, navigateTo, intl} = props;

    return (
      <div className="product">
        <div className='product-list'>
            {(productList || []).map((item) => (
                <ProductCard item={item} key={item.id} navigateTo={navigateTo} />
            ))}
        </div>
      </div>
    );
};

export default ProductList;
